import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { key_local_storage_user, urlapi } from "../../lib/backend/data";
import { toast } from "react-toastify";
// import localStorage from "redux-persist/es/storage";

const { useSelector } = require("react-redux")

const MantenedorEsquema = (props) => {
    const { rut_empresa } = props;
    const token = JSON.parse(localStorage.getItem(key_local_storage_user))
    const esquema_default = {
        actividad: { active: true, alias: "Viajes" },
        entregas: { active: true, alias: "Torre de control Envios" },
        ordenes: { active: true, alias: "Reportes y Pedidos Envios" },
        ordenes_2: { active: true, alias: "Rutas asignadas" },
        auditoria_app_movil: { active: true, alias: "Auditoria por pedido" },
        auditoria_vehiculos: { active: true, alias: "Auditoria por vehículo" },
        rendimiento_proveedores: { active: true, alias: "OTIF proveedores" },
        notificaciones: { active: true, alias: "Crear notificaciones" },
        tickets: { active: true, alias: "Tickets" },
        tickets_todos: { active: true, alias: "Todos" },
        tickets_pendientes: { active: true, alias: "Tickets pendientes" },
        tickets_proveedor: { active: true, alias: "Tickets recibidos" },
        tarifas: { active: true, alias: "Tarifas" },
        flota: { active: true, alias: "Flota" },
        mi_flota: { active: true, alias: "Mi flota" },
        monitoreo_flota: { active: true, alias: "Monitoreo flota" },
        informes_rastreo_conductor: { active: true, alias: "Informes de rastreo" },
        automatizaciones: { active: true, alias: "Alertas y notificaciones" },
        auditoria_conductores: { active: true, alias: "Reporte App Conductor" },
        expedicioness: { active: true, alias: "Expediciones" },
        invitaciones: { active: true, alias: "Invitaciones de rutas" },
        ruteo_simple: { active: true, alias: "Planificador" },
        ruteo_simple_2: { active: true, alias: "Planificar visitas" },
        ruteo_avanzado: { active: true, alias: "Planificar entregas" },
        facturacion: { active: true, alias: "Facturación" },
        configuracion: { active: true, alias: "Configuración" },
        general: { active: true, alias: "General" },
        empresa: { active: true, alias: "Mi Empresa" },
        productos: { active: true, alias: "Productos" },
        clientes: { active: true, alias: "Clientes" },
        mis_clientes: { active: true, alias: "Mis Clientes" },
        tarifas_clientes: { active: true, alias: "Tarifas" },
        clientes_bodegas: { active: true, alias: "Bodegas" },
        contactos: { active: true, alias: "Contactos" },
        integracioness: { active: true, alias: "Integraciones" }
    }  
    const [esquema, setEsquema] = useState(false)
    const [interfaz, setInterfaz] = useState(false)
    const [loading, setLoading] = useState(true)

    const handleInputChange = (event, propiedad) => {
        setEsquema(prev => {

            let actual = {
                ...prev,
                [propiedad]: {
                    ...prev[propiedad],
                    [event.target.name]: event.target.value,
                },
            }

            return {...{}, ...actual}
        });
    };

    const handleCheckChange = (event, propiedad) => {
        setEsquema({
            ...esquema,
            [propiedad]: {
                ...esquema[propiedad],
                active: event.target.checked
            }
        });
    };


    const guardarCambios = () => {
        const body = { ...interfaz, detalles: esquema }
        setLoading(true)
        fetch(`${urlapi}/configuracion/admin-interfaz`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json', 
                'Authorization': `Bearer: ${token.tokenSession}`
            },
            body: JSON.stringify(body)
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
            setLoading(false)
        })
        .catch(error => {
            console.error('Error:', error);
            setLoading(false)
        });
    };

    const obtenerInterfaz = () => {
        
        fetch(`${urlapi}/configuracion/admin-interfaz?propietario=${rut_empresa}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', 
                'Authorization': `Bearer: ${token.tokenSession}`
            },
        })
        .then(response => response.json())
        .then(data => {
            if(!data){
                toast.error("No se pudo obtener la configuración de interfaz")
            }else if(data.errorMessage){
                toast.error(data.errorMessage)
            }else if(data._id){
                setInterfaz(data)
                if(data.detalles){
                    setEsquema(data.detalles)
                }else{
                    setEsquema(esquema_default)
                }
            }
            setLoading(false)
            console.log(data);
        })
        .catch(error => {
            console.error('Error:', error);
            setLoading(false)
        });
    };

    useEffect(() => {
        obtenerInterfaz()
    },[])

    return (
        <div className="">
            <Col xs={12} className="mb-3">
                <i className="fa-solid fa-sitemap"></i>
            <h3>
                Configuración de interfaz
            </h3>
            <p>
                <i className="fa-solid fa-triangle-exclamation text-warning"></i> Aqui
                podrás personalizar el menú y los submenús correspondientes que se
                muestran en la barra lateral de tu interfaz de usuario.
            </p>

                <Row>
                    {Object.keys(esquema).map(propiedad => (
                        <Col sm={12} md={4} key={propiedad}>
                            <Card className="p-3 mb-3">
                            <Form.Group controlId={`formEsquema-${propiedad}`}>
                                    <Form.Check
                                        type="switch"
                                        className="mb-3"
                                        id={`switch-${propiedad}`}
                                        checked={esquema[propiedad].active}
                                        onChange={e => handleCheckChange(e, propiedad)}
                                        />
                                        
                                <Form.Control
                                    className="shadow-sm mr-3"
                                    name="alias"
                                    type="text"
                                    placeholder={esquema[propiedad]}
                                    value={esquema[propiedad].alias}
                                    onChange={e => handleInputChange(e, propiedad)}
                                    />

                            </Form.Group>

                            <Form.Group controlId={`formEsquema-${propiedad}`}>

                                <Form.Control
                                    className="shadow-sm mr-3"
                                    name="imagen"
                                    type="text"
                                    placeholder="Ruta de la imagen para este módulo"
                                    value={esquema[propiedad].imagen}
                                    onChange={e => handleInputChange(e, propiedad)}
                                    />

                            </Form.Group>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col>
                        <Button disabled={loading} variant="success" className="shadow-sm rounded mb-3" onClick={guardarCambios}>
                            GUARDAR CAMBIOS
                        </Button>
                    </Col>
                </Row>
            </Col>
        </div>
    );
};


export default MantenedorEsquema