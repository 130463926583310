import { Card, Col, Row } from "react-bootstrap"
import { fechaATexto } from "../../lib/helpers/helpers"
import { getDireccion, obtenerGeoValor } from "../../lib/helpers/pedidos/pedidos"
import MostrarGeoDatos from "./box_geo_datos"
import { FiMapPin } from "react-icons/fi"
import { BiUser } from "react-icons/bi"
import { Link } from "react-router-dom"
import { useState } from "react"

const BoxList = (props) => {
    const {
        hideAddress,
        partialAddress,
        typeView, 
        selected,
        setSelected,
    } = props;
    const pedido = props.pedido
    const seleccionado = props.seleccionado ? props.seleccionado : false
    const showGeoData = props.showGeoData ? props.showGeoData : false
    const tipo_vista = typeof typeView !== "undefined" ? typeView : false
    const hide_address = typeof hideAddress !== "undefined" ? hideAddress : false
    const partial_address = typeof partialAddress !== "undefined" ? partialAddress : false
    let destinatario = ''
    let titulo_estado = ''
    let titulo_sub_estado = ''
    let conductor = ''
    let patente = ''
    let color_estado = '#bebebe'
    let smallSizeFont = 12

    if(typeof pedido.orden === 'object'){
        if(typeof pedido.orden.billing === 'object'){
            destinatario = pedido.orden.billing.first_name
        }
    }

    const clickPedido = (id) => {
        if(props.onClickPedido) return props.onClickPedido(id)
        if (selected.includes(id)) {
            setSelected(prevSelected => prevSelected.filter(pedidoId => pedidoId !== id));
        } else {
            setSelected(prevSelected => [...prevSelected, id]);
        }
    }

      console.log('selected', selected)

    if(pedido.estado_entrega){
        if(typeof pedido.estado_entrega === 'object'){
            if(pedido.estado_entrega.titulo) titulo_estado = pedido.estado_entrega.titulo
            if(pedido.estado_entrega.color) color_estado = pedido.estado_entrega.color
        }
    }

    if(pedido.sub_estado_entrega){
        if(typeof pedido.sub_estado_entrega === 'object'){
            if(pedido.sub_estado_entrega.titulo) titulo_sub_estado = pedido.sub_estado_entrega.titulo
        }
    }

    if(pedido.conductor){
        if(typeof pedido.conductor === 'object'){
            if(pedido.conductor.nombres) conductor = pedido.conductor.nombres
            if(pedido.conductor.patente) patente = pedido.conductor.patente
        }
    }

    const mostrarVehiculo = (conductor, patente) => {
        if(!conductor) return "Sin asignar"
        let string = conductor
        if(patente) string = `${string} ${patente}`
        return string
    }

    const mostrarDireccion = (pedido) => {
        if(partial_address === true){
            let direccion = ''
            const nivel3 = obtenerGeoValor(pedido,'level3')
            if(nivel3) direccion = `${direccion} ${nivel3}`

            const nivel2 = obtenerGeoValor(pedido,'level2')
            if(nivel2) direccion = `${direccion} ${nivel2}`

            const nivel1 = obtenerGeoValor(pedido,'level1')
            if(nivel1) direccion = `${direccion} ${nivel1}`
            return direccion
        } else {
            return getDireccion(pedido)
        }
    }

    const MostrarGeoDatosComponent = () => {
        if(showGeoData !== true) return false
        return <MostrarGeoDatos pedido={pedido} selected={seleccionado}/>
    }

    let string_estado = `${titulo_estado} ${titulo_sub_estado}`
    if(!titulo_estado && !titulo_sub_estado) string_estado = "Sin datos"

    if(tipo_vista === "simple") return <Card bg={ seleccionado === true ? 'primary' : 'white' } className={` p-3 ${seleccionado === true ? 'text-white' : 'text-primary'}`} onClick={() => clickPedido(pedido._id)}>
    <Row>
        <Col xs={7}><h6 style={{ textTransform: "uppercase", fontSize: smallSizeFont, fontWeight: "bold" }} className="mb-0"><BiUser /> {destinatario}</h6></Col>
        <Col xs={5} className="text-right"><h6 style={{ fontSize:  smallSizeFont, fontWeight: "bold" }} className="mb-0">{pedido.pedido}</h6></Col>
        <Col xs={6} className="text-right"><h6 style={{ fontSize: smallSizeFont, fontWeight : "bold" }} className="mb-2">{patente}</h6></Col>
        { !hide_address ? <Col md={12}><h6 style={{ fontSize: smallSizeFont, color: "#aeaeae" }} className="mb-0"><FiMapPin /> {mostrarDireccion(pedido)}</h6></Col> : false }
    </Row>
    </Card>


    if(tipo_vista === "manifest") return (<>
        <Row>
            <Col xs={7}><h6 style={{ textTransform: "uppercase", fontSize: smallSizeFont, fontWeight: "bold" }} className="mb-0"><BiUser /> {destinatario}</h6></Col>
            <Col xs={5} className="text-right"><h6 style={{ fontSize:  smallSizeFont }} className="mb-0"><b>Ref:</b> {pedido.pedido}</h6></Col>
            <Col xs={6} className="text-right"><h6 style={{ fontSize: smallSizeFont, fontWeight : "bold" }} className="mb-2">{patente}</h6></Col>
            { !hide_address ? <Col md={12}><h6 style={{ fontSize: smallSizeFont }} className="mb-0"><FiMapPin /> {mostrarDireccion(pedido)}</h6></Col> : false }
        </Row>
    </>)


    return <Card style={{backgroundColor: seleccionado ? 'gray' : 'white'}} className={`shadow h-100 hover p-3 ${seleccionado === true ? 'text-white' : 'text-primary'}`} onClick={() => clickPedido(pedido._id)}>
        <Row>
            <Col xs={7}>
                <p style={{ fontSize:9, fontWeight:'normal', borderRadius:2, backgroundColor: color_estado, padding: 6, color:'white' }} className="mb-2">{string_estado.toUpperCase()}</p>
            </Col>
            <Col xs={5} className="text-right"><h6 style={{ fontWeight:700, fontSize: 14, padding: '5px 0px' }} className='mb-0'><Link to={`/ordenes/${pedido._id}`} className={`${seleccionado === true ? 'text-white' : 'text-primary'}`}>{pedido.pedido} <i className="fa-solid fa-up-right-from-square"></i></Link></h6></Col>
            <Col xs={6}><h6 style={{ fontSize:13 }} className="mb-0">{destinatario}</h6></Col>
            <Col xs={6} className="text-right"><h6 style={{ fontSize:13 }} className="mb-2">{patente}</h6></Col>
            { !hide_address ? <Col md={12}><h6 style={{ fontSize:16, fontWeight:"bold" }} className="mb-0">{mostrarDireccion(pedido)}</h6></Col> : false }
            <Col md={12}><h6 style={{ fontSize:13, fontWeight:'lighter' }} className="mb-0">{ fechaATexto(pedido.createdAt)}</h6></Col>
            <Col md={12}>
            <MostrarGeoDatosComponent pedido={pedido} />
            </Col>
        </Row>
    </Card>
}

export default BoxList