import { useCallback, useEffect, useState } from "react"
import { Card, Col, Form, Row, Spinner, Button } from "react-bootstrap"
import { estilo_last_mile } from "../../lib/global/styles"
import PantallaCargando from "../general/pantalla_cargando"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/session"
import { urlapi } from "../../lib/backend/data"
import { debounce } from "@mui/material"
import CargaImagenes from "../general/carga_imagenes"
import SelectorEstados from "./selector"
import { DateTime } from 'luxon';
import { main_modules } from "../general/data"


const ConfiguracionPreciosApis = (props) => {
    
    const ahora = DateTime.now();
    const mesActual = ahora.toFormat('MM');
    const anoActual= ahora.toFormat('yyyy');
    const [mes, setMes] = useState(mesActual)
    const [ano, setAno] = useState(anoActual)

    const estructura = {
        modulos: main_modules,
        mes,
        ano
    }


    const [ id, setId ] = useState("")
    const [ estructuraActual, setEstructuraActual ] = useState(estructura)
    const [ loading, setLoading ] = useState(false)
    const [ pendienteGuardar, setPendienteGuardar ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ loadingGuardado, setLoadingGuardado ] = useState(false)
    const [messageAllert, setMessageAlert] = useState("")
    const token = session.tokenSession
    const dispatch = useDispatch()
    const gris = '#e5e5e5'
    const sub_tipo = "precios-apis"


    useEffect(() => {
        obtenerDatos(sub_tipo)
    }, [])

    const obtenerDatos = async (modulo)=>{
            if(ano.length !== 4) return toast.error("El año debe contener 4 digitos")
        setLoading(true)
        return fetch(`${urlapi}/configuracion/personalizar/precios/apis`,{
            method:'POST',
            body: JSON.stringify({
                tipo: "logistica",
                sub_tipo: modulo,
                detalles: estructura
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setId(res._id)

                for( const modulo of estructura.modulos){
                    const i = res.detalles.modulos.findIndex(mod => mod.slug === modulo.slug)
                    if(i > -1){
                        let crear_modulo = res.detalles.modulos[i]
                        Object.keys(modulo).forEach(key => {
                            if(typeof crear_modulo[key] === "undefined") crear_modulo[key] = modulo[key]
                        })
                        console.log(crear_modulo)
                        res.detalles.modulos[i] = crear_modulo
                    } else {
                        res.detalles.modulos.push(modulo)
                    }
                }

                setEstructuraActual(res.detalles)
            }
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }
    
    const guardarAutomatico = async (payload)=>{
        setLoadingGuardado(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            }
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
        .catch(error => {
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
    }
    
    const actualizar = useCallback(debounce((data) => guardarAutomatico(data), 1000), []);

    const tabla_registros = () => {
        return <div>
    <Row>
            {
                estructuraActual.modulos.map((modulo,imodulo) => {
                    let defaultValue = []
                    if(Array.isArray(modulo.options)) if(modulo.options.length > 0){
                        defaultValue = modulo.options.map(e => e._id)
                    }
                    return <Col xs={6} key={`modulo-${imodulo}`}  className="mb-3">
                        <Card className="p-3">
                        <h4 className="mb-0" style={{ fontWeight: "bold" }}>{modulo.nombre}</h4>
                        <label >precio</label>
                        <input type="number" className="form-control" defaultValue={modulo.precio} onChange={(e) => handleChangeStatus(e, imodulo)}/>                        
                        {/* <SelectorEstados tipo_dato="subestados" key={`modulo-${imodulo}`} defaultValue={defaultValue} isMulti={true} full_payload={true} onChange={(e) => handleChangeStatus(e, imodulo)} /> */}
                        </Card>
                    </Col>
                })
            }
        </Row>

        </div>
    }
    

    const handleChangeMes = (e) => {
        const {value} = e.target
       return setMes(value)

    }
    const handleChangeAno = (e) => {
        const {value} = e.target
        if(value.length !== 4) {setMessageAlert("¡ debes ingresar 4 numeros para el año !")
        }else{ setMessageAlert("")}        
       return setAno(value)
    }


   const handleChangeStatus = (e,i_movulo) => {
        console.log(e)
        setEstructuraActual(prev => {
            let actual = {...prev}
            actual.modulos[i_movulo].precio = parseFloat(e.target.value)
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        })
    }
    if(loading) return <PantallaCargando />

    return <div>
        <Row>
             
            <Col className="text-right text-secondary"><p>{pendienteGuardar ? "Pendiente de guardar cambios" : <b className="text-success" style={{ fontWeight: "bold" }}>¡Guardado!</b> } { loadingGuardado ? <Spinner size="sm" animation="border" variant="primary" /> : false }</p></Col>
        </Row>

        <Row>
            <Col md={12}>
            <h4>Precios de Apis por mes y año</h4>
                <Row>
                    <Col md={4}>
                        <label className='form-control-label d-block'>Seleccionar Mes</label>
                        <select className='form-control mb-4' name="mes" defaultValue={mes} onChange={(e)=>handleChangeMes(e)} >
                            {/* <option value="" >Seleccione</option> */}
                            <option value="01" >Enero</option>
                            <option value="02" >Febrero</option>
                            <option value="03" >Marzo</option>
                            <option value="04" >Abril</option>
                            <option value="05" >Mayo</option>
                            <option value="06" >Junio</option>
                            <option value="07" >Julio</option>
                            <option value="08" >Agosto</option>
                            <option value="09" >Septiembre</option>
                            <option value="10" >Octubre</option>
                            <option value="11" >Noviembre</option>
                            <option value="12" >Diciembre</option>
                          
                        </select>
                        </Col>
                    <Col md={4}>
                        <label className='form-control-label d-block'>Ingresar Año</label>
                        <input type="number" className='form-control mb-4' defaultValue={parseInt(ano)} onChange={(e)=>handleChangeAno(e)} />
                        {messageAllert ? <p style={{color: "red"}}>{messageAllert}</p> : false}                         
                    </Col>
                    <Col md={4}>
                    <Button className='mt-4' onClick={()=>obtenerDatos(sub_tipo)}>ACTUALIZAR</Button>
                    </Col>
                    <Col md={12}>
                    {tabla_registros()}
                    </Col>
                </Row>
            </Col>
        </Row>
        
        
        
    </div>
}

export default ConfiguracionPreciosApis