import { useState, useEffect } from "react"
import { Button, Col, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from "react-bootstrap"
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/session";
import CamposObligatoriosLeyenda from "../general/campos_obligatorios";
import { validateEmail } from '../../lib/helpers/helpers'

const DetailFull = (props) => {
    const [proveedor, setProveedor] = useState(props.proveedor ? props.proveedor : false)
    const [loading, setLoading] = useState(false)
    const [removiendo, setRemoviendo] = useState(false)
    const [nuevoProveedor, setNuevoProveedor] = useState({})
    const session = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const token = session.tokenSession
    const [crear, setCrear] = useState(props.crear ? props.crear : false)
    const propietario = props.propietario ? props.propietario : ""

    const dispatch = useDispatch()
    
    const bancos = [
        { value: 'banco-de-chile', label: 'Banco de Chile' },
        { value: 'banco-estado', label: 'Banco Estado' },
        { value: 'santander-chile', label: 'Santander Chile' },
        { value: 'bci', label: 'Banco de Crédito e Inversiones (BCI)' },
        { value: 'banco-bice', label: 'Banco BICE' },
        { value: 'banco-security', label: 'Banco Security' },
        { value: 'scotiabank-chile', label: 'Scotiabank Chile' },
        { value: 'itau-chile', label: 'Itaú Corpbanca' },
        { value: 'banco-falabella', label: 'Banco Falabella' },
        { value: 'banco-ripley', label: 'Banco Ripley' },
        { value: 'banco-consorcio', label: 'Banco Consorcio' },
        { value: 'btg-pactual-chile', label: 'BTG Pactual Chile' }
    ]

    const handleChange = (e) => {
        const { name, value } = e.target
        proveedor[name] = value
        return setProveedor(proveedor)
    }

    const crearNuevo = async () => {
        const requeridos = [
            { value: "titulo", label: "Título" },
            { value: "banco", label: "Banco" },
            { value: "dni", label: "DNI" },
            { value: "tipo", label: "Tipo" },
            { value: "numero", label: "Numero" },
            { value: "email", label: "Email" }
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!nuevoProveedor[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: "${faltantes.join(', ')}". No deben haber letras en campos numericos.`)
        if(validateEmail(nuevoProveedor.email) !== true) return toast.error('Email inválido')
        setLoading(true)
        return fetch(`${data.urlapi}/metodos/pago`, {
            method: 'POST',
            body: JSON.stringify(nuevoProveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                    if(props.onProveedorNuevo) props.onProveedorNuevo(pros)
                    toast.success(`Realizado exitosamente`)
                    setNuevoProveedor({...{}, ...{}})
              }
              
              return setLoading(false)
          })
          .catch(error => {
            toast.error("No se pudo crear el registro, revisa el error en la consola")
                return setLoading(false)
          })
    }

    const guardarCambios = async () => {
        setLoading(true)
        if(validateEmail(proveedor.email) !== true) return toast.error('Email inválido')
        return fetch(`${data.urlapi}/metodos/pago`, {
            method: 'PUT',
            body: JSON.stringify(proveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Hubo un error de servidor', this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage, this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                  if(props.onProveedorGuardado) props.onProveedorGuardado(proveedor)
                  toast.success(`Guardado exitosamente`)
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro, No deben haber letras en campos numericos, ni campos vacios.')
                return setLoading(false)
          })
    }

    const handleChangeNuevo = (e) => {
        const { name, value } = e.target
        nuevoProveedor[name] = value
        console.log("nuevoProveedor", nuevoProveedor)
        return setNuevoProveedor(nuevoProveedor)
    }

    const formularioNuevo = () => {
        return <div>
            <Row>
                <Col md={12}>
                    <h4>Crear nuevo Metodo de Pago</h4>
                    <p>Los metodos de pago forman parte de tu empresa, están relacionados directamente a la rentabilización de la misma en esta plataforma.</p>
                    <CamposObligatoriosLeyenda />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Titulo *</label>
                    <input className="form-control" name="titulo" defaultValue={nuevoProveedor.titulo} onChange={handleChangeNuevo} />
                </Col>
                <Col md={3} className="mb-3">
                            <label className="form-control-label d-block">Banco *</label>                
                        <select className="form-control" name="banco" defaultValue={nuevoProveedor.banco} onChange={handleChangeNuevo} >
                            <option  value="">Selecciona </option>
                            {
                                bancos.map(banco => <option key={`banco-${banco.value}`} value={banco.value}>{banco.label}</option>)
                            }
                        </select>
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">DNI *</label>
                    <input className="form-control" name="dni" defaultValue={nuevoProveedor.dni} onChange={handleChangeNuevo} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Tipo *</label>
                    <select className="form-control" name="tipo" onChange={handleChangeNuevo}>
                    <option  value="">Selecciona </option>
                    <option  value="corriente">Corriente </option>
                    <option  value="ahorro">Ahorro </option>
                    <option  value="vista">Vista </option>
                  </select>
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Numero *</label>
                    <input className="form-control" name="numero" defaultValue={nuevoProveedor.numero} onChange={handleChangeNuevo} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Email *</label>
                    <input className="form-control" name="email" defaultValue={nuevoProveedor.email} onChange={handleChangeNuevo} />
                </Col>
                <Col md={12}>
                    {
                        loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>crearNuevo()} >CREAR NUEVO</Button>
                    }
                </Col>
            </Row>
        </div>
    }

    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/metodos/pago?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                if(props.onFieldDeleted) props.onFieldDeleted(res._id)
            }
            setProveedor(false)
            setCrear(true)
            toast.success(`Eliminado exitosamente`)
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const View = () => {
        if(!proveedor) return formularioNuevo()
        return <div>
            <h4 className="mb-0">Detalles del metodo de pago <b>{proveedor.titulo}</b></h4>
            {
                removiendo === true ? <Spinner animation="border" /> : <Button variant="link" className="text-danger p-0 d-block mb-3" onClick={()=>solicitarEliminar(proveedor._id)} >Eliminar</Button>
            }
            <Tabs defaultActiveKey="editar" >
            <Tab eventKey="editar" className="mt-4" title="Ficha informativa ">
                <Row>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Titulo *</label>
                    <input className="form-control" name="titulo" defaultValue={proveedor.titulo} onChange={handleChange} />
                </Col>
                <Col md={3} className="mb-3">
                            <label className="form-control-label d-block">Banco *</label>
                        <select className="form-control" name="banco" defaultValue={proveedor.banco} onChange={handleChange} >
                            <option  value="">Selecciona </option>
                            {
                                bancos.map(banco => <option key={`banco-${banco.value}`} value={banco.value}>{banco.label}</option>)
                            }
                        </select>
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">DNI *</label>
                    <input className="form-control" name="dni" defaultValue={proveedor.dni} onChange={handleChange} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Tipo *</label>
                    <select className="form-control" defaultValue={proveedor.tipo} name="tipo" onChange={handleChange}>
                    <option  value="">Selecciona </option>
                    <option  value="corriente">Corriente </option>
                    <option  value="ahorro">Ahorro </option>
                    <option  value="vista">Vista </option>
                  </select>
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Numero *</label>
                    <input className="form-control" name="numero" defaultValue={proveedor.numero} onChange={handleChange} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Email *</label>
                    <input className="form-control" name="email" defaultValue={proveedor.email} onChange={handleChange} />
                </Col>
        <Col md={12}>
            {
                loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>guardarCambios()} >GUARDAR CAMBIOS</Button>
            }
        </Col>
        </Row>
            </Tab>
           {/* <Tab eventKey="bodegas" className="mt-4" title="Bodegas" >
                <DestinatariosBodegasListado id_cliente={proveedor._id} condicion_default={{ id_cliente: proveedor._id }} />
            </Tab>
            <Tab eventKey="contactos" className="mt-4" title="Contactos" >
                <DestinatariosContactosListado id_cliente={proveedor._id} condicion_default={{ id_cliente: proveedor._id }} />
            </Tab> */}
        </Tabs>
        </div>
    }

    return <div>
        {View()}
    </div>
}

export default DetailFull