import { useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { estilo_last_mile } from '../../lib/global/styles';
import { Link, useNavigate } from 'react-router-dom';
import { rutas } from '../../lib/routes/routes';
import { BiMenu } from 'react-icons/bi';
import { modos_sistema, url_images } from '../../lib/global/data';
import { FaArrowLeft, FaClipboardList, FaPlusCircle, FaRegHandshake } from 'react-icons/fa';
import TopBar from './topbar';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import { AiFillCar, AiOutlineCode, AiOutlineHome, AiOutlineProfile } from 'react-icons/ai';
import { MdOutlineBusinessCenter, MdOutlineMonitorHeart } from 'react-icons/md';
import { PiWarehouseBold } from 'react-icons/pi';
import { RiRouteLine, RiShutDownLine } from 'react-icons/ri';
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { Button } from 'react-bootstrap';
import { HiOutlineTruck } from "react-icons/hi2";
import { IoTicketOutline } from 'react-icons/io5';
import { TbBuildingFactory2, TbRoute, TbUsersGroup } from 'react-icons/tb';
import { IoMdSettings } from 'react-icons/io';

const esquemax = {
    actividad: { active: true, alias: "" },
    entregas: { active: true, alias: "" },
    estados_carga: { active: true, alias: "" },
    auditoria_app_movil: { active: true, alias: "" },
    rendimiento_proveedores: { active: true, alias: "" },
    operacion_en_curso: { active: true, alias: "" },
    ordenes: { active: true, alias: "" },
    mis_ordenes: { active: true, alias: "" },
    crear_nueva: { active: true, alias: "" },
    importar_excel: { active: true, alias: "" },
    importar_avanzado: { active: true, alias: "" },
    proveedores: { active: true, alias: "" },
    listado: { active: true, alias: "" },
    flota: { active: true, alias: "" },
    mi_flota: { active: true, alias: "" },
    monitoreo_flota: { active: true, alias: "" },
    alertas: { active: true, alias: "" },
    monitoreo_alertas: { active: true, alias: "" },
    informes_rastreo_conductor: { active: true, alias: "" },
    categorias: { active: true, alias: "" },
    automatizaciones: { active: true, alias: "" },
    importar_excel: { active: true, alias: "" },
    clientes: { active: true, alias: "" },
    facturacion_cliente: { active: true, alias: "" },
    rutas: { active: true, alias: "" },
    mis_rutas: { active: true, alias: "" },
    invitaciones: { active: true, alias: "" },
    destinatarios: { active: true, alias: "" },
    destinatarios_bodega_general: { active: true, alias: "" },
    destinatarios_listado_general: { active: true, alias: "" },
    bodega: { active: true, alias: "" },
    mis_bodegas: { active: true, alias: "" },
    expediciones: { active: true, alias: "" },
    etiquetas: { active: true, alias: "" },
    integraciones: { active: true, alias: "" },
    crear_unitario: { active: true, alias: "" },
    payments: { active: true, alias: "" },
  };

  const esquema_proveedores = {
    actividad: { active: true, alias: "" },
    entregas: { active: true, alias: "" },
    estados_carga: { active: true, alias: "" },
    auditoria_app_movil: { active: true, alias: "" },
    rendimiento_proveedores: { active: true, alias: "" },
    operacion_en_curso: { active: true, alias: "" },
    ordenes: { active: true, alias: "" },
    mis_ordenes: { active: true, alias: "" },
    crear_nueva: { active: true, alias: "" },
    importar_excel: { active: true, alias: "" },
    importar_avanzado: { active: true, alias: "" },
    proveedores: { active: true, alias: "" },
    listado: { active: true, alias: "" },
    flota: { active: true, alias: "" },
    mi_flota: { active: true, alias: "" },
    monitoreo_flota: { active: true, alias: "" },
    alertas: { active: true, alias: "" },
    monitoreo_alertas: { active: true, alias: "" },
    informes_rastreo_conductor: { active: true, alias: "" },
    categorias: { active: true, alias: "" },
    automatizaciones: { active: true, alias: "" },
    importar_excel: { active: true, alias: "" },
    clientes: { active: true, alias: "" },
    facturacion: { active: true, alias: "" },
    facturacion_cliente: { active: true, alias: "" },
    rutas: { active: true, alias: "" },
    mis_rutas: { active: true, alias: "" },
    invitaciones: { active: true, alias: "" },
    destinatarios: { active: true, alias: "" },
    destinatarios_bodega_general: { active: true, alias: "" },
    destinatarios_listado_general: { active: true, alias: "" },
    bodega: { active: true, alias: "" },
    mis_bodegas: { active: true, alias: "" },
    expediciones: { active: true, alias: "" },
    etiquetas: { active: true, alias: "" },
    integraciones: { active: true, alias: "" }
  };

  
  const Structure = (props) => {
    const {
      router
    } = props
    const [collapsed, setCollapsed] = useState(false);
    const icons_style = { maxWidth: 25 }
    const esquema_default = useSelector(state => state.esquema_actual)
    const [ esquema, setEsquema ] = useState(esquema_default)
    const dispatch = useDispatch()
    const findAlias = (slug, title) => {
        if(!esquema[slug]) return title
        if(esquema[slug].alias) return esquema[slug].alias
        return title
    }
    
    const findActiveness = (slug) => {
        if(!esquema[slug]) return true
        return esquema[slug].active === true
    }
    const modoUsuario = useSelector(state => state.modo)

    const menuItemStyleClient = {
        root: {
            fontSize: 14,
            fontWeight: 700,
            color: 'black',
            // backgroundImage: `url(${url_images}/background.webp)`,
            // backgroundSize: "cover"
            backgroundColor: "white"
        },
        button: {
            '&:hover': {
                backgroundColor: "white"
            },
        },
        SubMenuExpandIcon: {
            color: 'black',
        },
        subMenuContent: ({ level }) => ({
            backgroundColor: level === 0 ? "#f1f1f1" : '#f1f1f1', // Cambia el color de fondo del submenú aquí
        })
    }

    const menuStyleProvider = {
        root: {
            fontSize: 14,
            fontWeight: 700,
            color: 'white', // Ajusta el color del texto a blanco,
            backgroundColor: "transparent"
        },
        button: {
            '&:hover': {
                backgroundColor: "transparent"
            }
        },
        SubMenuExpandIcon: {
            color: "white",
        },
        subMenuContent: ({ level }) => ({
            backgroundColor: level === 0 ? estilo_last_mile.color_primary_dark_ : 'transparent', // Cambia el color de fondo del submenú aquí
        })
    }

    const estructura = [
        {
          type: "submenu",
          slug: "actividad",
          title: "Actividad",
          icon: <MdOutlineMonitorHeart size={28} />,
          options: [
            {
              slug: "entregas",
              title: "Entregas",
              route: rutas.actividad.slug
            },
            {
              slug: "estados_carga",
              title: "Estados de carga",
              route: rutas.actividad_estados.slug
            },
            {
              slug: "auditoria_app_movil",
              title: "Auditoria de entregas",
              route: rutas.actividad_auditoria.slug
            },
            {
              slug: "rendimiento_proveedores",
              title: "Rendimiento de proveedores",
              route: rutas.rendimiento_proveedores.slug
            },
            {
              slug: "entregas_asignadas_pendientes",
              title: "Entregas asignadas pendientes",
              route: rutas.operacion_en_curso.slug
            }
          ]
        },
        {
          type: "menuitem",
          slug: "ordenes",
          title: "Órdenes",
          icon: <AiOutlineProfile size={28} />,
          route: rutas.ordenes.slug
          // options: [
          //   {
          //     slug: "mis_ordenes",
          //     title: "Mis Órdenes",
          //     route: rutas.ordenes.slug
          //   },
          //   {
          //     slug: "crear_nueva",
          //     title: "Crear nueva",
          //     route: rutas.ordenes_crear.slug
          //   },
          //   {
          //     slug: "importar_excel",
          //     title: "Importar por excel",
          //     route: rutas.rutas_importar_excel.slug
          //   },
          //   {
          //     slug: "importar_avanzado",
          //     title: "Importar avanzado",
          //     route: rutas.rutas_importar_avanzado.slug
          //   }
          // ]
        },
        {
          type: "submenu",
          slug: "flota",
          title: "Flota",
          icon: <AiFillCar size={28} />,
          options: [
            {
              slug: "mi_flota",
              title: "Mi flota",
              route: rutas.vehiculos.slug
            },
            {
              slug: "monitoreo_flota",
              title: "Monitoreo flota",
              route: rutas.actividad_flota.slug
            },
            {
              slug: "alertas",
              title: "Alertas",
              route: rutas.alerts.slug
            },
            {
              slug: "monitoreo_alertas",
              title: "Monitoreo alertas",
              route: rutas.map_alerts.slug
            },
            {
              slug: "informes_rastreo_conductor",
              title: "Informes de rastreo",
              route: rutas.estadisticas_tracking_conductor.slug
            },
            {
              slug: "categorias",
              title: "Categorías",
              route: rutas.proveedores.slug
            },
            {
              slug: "automatizaciones",
              title: "Alertas",
              route: rutas.zones.slug
            },
            {
              slug: "importar_excel",
              title: "Importar por excel",
              route: rutas.vehiculos_importar_excel.slug
            }
          ]
        },
        {
          type: "menuitem",
          slug: "clientes",
          title: "Clientes",
          route: rutas.clientes.slug,
          icon: <FaRegHandshake size={28} />
        },
        {
          type: "menuitem",
          slug: "productos",
          title: "Productos",
          icon: <HiOutlineTruck  size={28} />,
          route: rutas.productos.slug
        },
        {
          type: "menuitem",
          slug: "facturacion",
          title: "Facturación",
          route: rutas.facturacion.slug,
          icon: <FaMoneyCheckDollar size={28} />
        },
        {
          type: "submenu",
          slug: "ruteo_simple",
          title: "Planificador",
          icon: <TbRoute size={28} />,
          options: [
            {
              type: "menuitem",
              slug: "ruteo_simple",
              title: "Planificar visitas",
              route: rutas.ruteo_simple.slug
            },
            {
              type: "menuitem",
              slug: "ruteo_avanzado",
              title: "Planificar entregas",
              route: rutas.ruteo_avanzado.slug
            }
          ]
          
        },
        {
          type: "submenu",
          slug: "rutas",
          title: "Rutas",
          icon: <RiRouteLine size={28} />,
          options: [
            {
              slug: "mis_rutas",
              title: "Mis rutas",
              route: rutas.rutas.slug
            },
            {
              slug: "invitaciones",
              title: "Invitaciones",
              route: rutas.rutas_asignaciones.slug
            }
          ]
        },
        {
          type: "submenu",
          slug: "destinatarios",
          title: "Destinatarios",
          icon: <FaClipboardList size={22} />,
          options: [
            {
              slug: "listado",
              title: "Listado",
              route: rutas.destinatarios.slug
            },
            {
              slug: "destinatarios_bodegas_general",
              title: "Bodegas",
              route: rutas.destinatarios_bodegas_general.slug
            },
            {
              slug: "destinatarios_contactos_general",
              title: "Contactos",
              route: rutas.destinatarios_contactos_general.slug
            }
          ]
        },
        {
          type: "submenu",
          slug: "bodega",
          title: "Bodega",
          icon: <PiWarehouseBold size={28} />,
          options: [
            {
              slug: "mis_bodegas",
              title: "Mis bodegas",
              route: rutas.bodega_bodegas.slug
            },
            {
              slug: "expediciones",
              title: "Expediciones",
              route: rutas.bodega_expediciones.slug
            },
            {
              slug: "etiquetas",
              title: "Etiquetas",
              route: rutas.etiquetas.slug
            }
          ]
        },
        {
          type: "submenu",
          slug: "integraciones",
          title: "Integraciones",
          icon: <AiOutlineCode size={28} />,
          options: [
            {
              slug: "integraciones",
              title: "Integraciones",
              route: rutas.integraciones.slug
            }
          ]
        }
      ];
      
      const estructura_cliente = [
        {
          type: "submenu",
          slug: "actividad",
          title: "Viajes",
          icon: <MdOutlineMonitorHeart size={28} />,
          options: [
            {
              slug: "entregas",
              title: "Torre de control Envios",
              route: rutas.actividad.slug
            },
            {
              slug: "ordenes",
              title: "Reportes y Pedidos Envios",
              route: rutas.ordenes.slug
            },
            {
              slug: "ordenes",
              title: "Rutas asignadas",
              route: rutas.rutas.slug
            },
            // {
            //   slug: "estados_carga",
            //   title: "Eventos en ruta",
            //   route: rutas.actividad_estados.slug
            // },
            {
              slug: "auditoria_app_movil",
              title: "Auditoria por pedido",
              route: rutas.actividad_auditoria.slug
            },
            {
              slug: "auditoria_vehiculos",
              title: "Auditoria por vehículo",
              route: rutas.auditoria_conductores.slug
            },
            {
              slug: "rendimiento_proveedores",
              title: "OTIF proveedores",
              route: rutas.rendimiento_proveedores.slug
            },
            {
              slug: "notificaciones",
              title: rutas.notificaciones_crear.title,
              route: rutas.notificaciones_crear.slug
            },
          ]
        },
        {
          type: "submenu",
          slug: "tickets",
          title: "Tickets",
          icon: <IoTicketOutline size={28} />,
          options: [
            {
              type: "menuitem",
              slug: "tickets-todos",
              title: "Todos",
              // icon: <IoTicketOutline size={28} />,
              route: rutas.tickets.slug
            },
            {
              type: "menuitem",
              slug: "tickets-pendientes",
              title: "Tickets pendientes",
              // icon: <IoTicketOutline size={28} />,
              route: rutas.tickets_pending.slug
            },
            {
              type: "menuitem",
              slug: "tickets-proveedor",
              title: "Tickets recibidos",
              // icon: <IoTicketOutline size={28} />,
              route: rutas.tickets_business.slug
            },
          ]
        },
        // {
        //   type: "menuitem",
        //   slug: "ordenes",
        //   title: "Órdenes",
        //   icon: <AiOutlineProfile size={28} />,
        //   route: rutas.ordenes.slug,
        //   options: [
        //     {
        //       slug: "mis_ordenes",
        //       title: "Mis Órdenes",
        //       route: rutas.ordenes.slug
        //     },
        //     {
        //       slug: "crear_nueva",
        //       title: "Crear nueva",
        //       route: rutas.ordenes_crear.slug
        //     },
        //     {
        //       slug: "importar_excel",
        //       title: "Importar por excel",
        //       route: rutas.rutas_importar_excel.slug
        //     },
        //     {
        //       slug: "importar_avanzado",
        //       title: "Importar avanzado",
        //       route: rutas.rutas_importar_avanzado.slug
        //     }
        //   ]
        // },
        // {
        //   type: "menuitem",
        //   slug: "proveedores",
        //   title: "Proveedores",
        //   icon: <MdOutlineBusinessCenter size={28} />,
        //   route: rutas.logistic_providers.slug
        //   // options: [
        //   //   {
        //   //     slug: "listado",
        //   //     title: "Listado",
              
        //   //   }
        //   // ]
        // },
        {
          type: "menuitem",
          slug: "tarifas",
          title: "Tarifas",
          icon: <MdOutlineBusinessCenter size={28} />,
          route: rutas.logistic_providers_pricing.slug
        },
        {
          type: "submenu",
          slug: "flota",
          title: "Flota",
          icon: <AiFillCar size={28} />,
          options: [
            {
              slug: "mi_flota",
              title: "Mi flota",
              route: rutas.vehiculos.slug
            },
            {
              slug: "monitoreo_flota",
              title: "Monitoreo flota",
              route: rutas.actividad_flota.slug
            },
            {
              slug: "informes_rastreo_conductor",
              title: "Informes de rastreo",
              route: rutas.estadisticas_tracking_conductor.slug
            },
            {
              slug: "automatizaciones",
              title: "Alertas y notificaciones",
              route: `${rutas.zones.slug}/new`
            },
            {
              slug: "auditoria_conductores",
              title: "Reporte App Conductor",
              route: `${rutas.auditoria_conductores.slug}`
            },
            {
              slug: "expedicioness",
              title: "Expediciones",
              route: rutas.bodega_expediciones.slug
            },
            {
              slug: "invitaciones",
              title: "Invitaciones de rutas",
              route: rutas.rutas_asignaciones.slug
            },
          ]
        },
        {
          type: "submenu",
          slug: "ruteo_simple",
          title: "Planificador",
          icon: <TbRoute size={28} />,
          options: [
            {
              type: "menuitem",
              slug: "ruteo_simple",
              title: "Planificar visitas",
              route: rutas.ruteo_simple.slug
            },
            {
              type: "menuitem",
              slug: "ruteo_avanzado",
              title: "Planificar entregas",
              route: rutas.ruteo_avanzado.slug
            }
          ]
          
        },
        {
            type: "menuitem",
            slug: "facturacion",
            title: "Facturación",
            icon: <FaMoneyCheckDollar size={28} />,
            route: rutas.facturacion_cliente.slug
            // options: [
            //   {
            //     slug: "facturacion",
            //     title: "Facturación",
                
            //   }
            // ]
          },
        // {
        //   type: "submenu",
        //   slug: "rutas",
        //   title: "Rutas",
        //   icon: <RiRouteLine size={28} />,
        //   options: [
        //     {
        //       slug: "mis_rutas",
        //       title: "Mis rutas",
        //       route: rutas.rutas.slug
        //     },
        //     {
        //       slug: "invitaciones",
        //       title: "Invitaciones",
        //       route: rutas.rutas_asignaciones.slug
        //     }
        //   ]
        // },
        {
          type: "submenu",
          slug: "configuracion",
          title: "Configuración",
          icon: <IoMdSettings size={22} />,
          options: [
            {
              type: "menuitem",
              slug: "general",
              title: "General",
              // icon: <FaClipboardList size={22} />,
              route: 'configuracion'
            },
            {
              type: "menuitem",
              title: rutas.planes.title,
              // icon: <FaClipboardList size={22} />,
              route: rutas.planes.slug
            },
            {
              type: "menuitem",
              title: rutas.metodos_pago.title,
              // icon: <FaClipboardList size={22} />,
              route: rutas.metodos_pago.slug
            },
            // {
            //   type: "menuitem",
            //   slug: "cuenta",
            //   title: "Mi cuenta",
            //   // icon: <FaClipboardList size={22} />,
            //   route: "account"
            // },
            {
              type: "menuitem",
              slug: "empresa",
              title: "Mi Empresa",
              route: "usuarios"
            },
            {
              type: "menuitem",
              slug: "productos",
              title: "Productos",
              // icon: <HiOutlineTruck  size={28} />,
              route: rutas.productos.slug
            },
          ]
        },
        {
          type: "submenu",
          slug: "clientes",
          title: "Clientes",
          icon: <TbUsersGroup size={22} />,
          options: [
            {
              type: "menuitem",
              slug: "mis-clientes",
              title: "Mis Clientes",
              // icon: <FaClipboardList size={22} />,
              route: rutas.destinatarios.slug
            },
            // {
            //   type: "menuitem",
            //   slug: "cuenta",
            //   title: "Mi cuenta",
            //   // icon: <FaClipboardList size={22} />,
            //   route: "account"
            // },
            {
              type: "menuitem",
              slug: "clientes-bodegas",
              title: "Bodegas",
              route: rutas.destinatarios_bodegas_general.slug
            },
            {
              type: "menuitem",
              slug: "contactos",
              title: "Contactos",
              // icon: <HiOutlineTruck  size={28} />,
              route: rutas.destinatarios_contactos_general.slug
            },
          ]
        },
        {
            type: "menuitem",
            slug: "empresas",
            title: "Empresas",
            icon: <TbBuildingFactory2 size={28} />,
            route: rutas.empresas.slug
        },
        {
            type: "menuitem",
            slug: rutas.planes.slug,
            title: rutas.planes.title,
            icon: <TbBuildingFactory2 size={28} />,
            route: rutas.planes.slug
        },
        {
            type: "menuitem",
            slug: "integracioness",
            title: "Integraciones",
            icon: <AiOutlineCode size={28} />,
            route: rutas.integraciones.slug
            // options: [
            //   {
            //     slug: "integraciones",
            //     title: "Integraciones",
                
            //   }
            // ]
          }
      ];
      
      const estructura_courrier = [
        {
          type: "menuitem",
          slug: "dashboard",
          title: "Panel de control",
          icon: <MdOutlineBusinessCenter size={28} />,
          route: rutas.dashboard_courriers.slug
        },
        {
          type: "menuitem",
          slug: "ruteo_simple",
          title: "Planificador",
          icon: <TbRoute size={28} />,
          route: rutas.ruteo_simple.slug
        },
        {
          type: "submenu",
          slug: "actividad",
          title: "Viajes",
          icon: <MdOutlineMonitorHeart size={28} />,
          options: [
            {
              slug: "entregas",
              title: "Torre de control",
              route: rutas.actividad.slug
            },
            {
              slug: "ordenes",
              title: "Reportes y Pedidos",
              route: rutas.ordenes.slug
            },
            {
              slug: "ordenes",
              title: "Rutas de vehículos",
              route: rutas.rutas.slug
            },
            // {
            //   slug: "estados_carga",
            //   title: "Eventos en ruta",
            //   route: rutas.actividad_estados.slug
            // },
            {
              slug: "auditoria_app_movil",
              title: "Auditoria de entregas",
              route: rutas.actividad_auditoria.slug
            },
            {
              slug: "rendimiento_proveedores",
              title: "OTIF, Medición y control",
              route: rutas.rendimiento_proveedores.slug
            }
          ]
        },
        {
          type: "menuitem",
          slug: "tickets",
          title: "Tickets",
          icon: <IoTicketOutline size={28} />,
          route: rutas.tickets.slug
        },
        {
          type: "menuitem",
          slug: "proveedores",
          title: "Proveedores",
          icon: <MdOutlineBusinessCenter size={28} />,
          route: rutas.logistic_providers.slug
        },
        {
          type: "menuitem",
          slug: "destinatarios",
          title: "Destinatarios",
          icon: <FaClipboardList size={22} />,
          route: rutas.destinatarios.slug
        },
        {
          type: "menuitem",
          slug: "bodega",
          title: "Bodega",
          icon: <PiWarehouseBold size={28} />,
          route: rutas.bodega_bodegas.slug
        },
        {
          type: "menuitem",
          slug: "recolecciones",
          title: "Recolecciones",
          icon: <HiOutlineTruck  size={28} />,
          route: rutas.recolecciones.slug
        },
        {
          type: "menuitem",
          slug: "productos",
          title: "Productos",
          icon: <HiOutlineTruck  size={28} />,
          route: rutas.productos.slug
        },
        {
          type: "submenu",
          slug: "facturacion",
          title: "Pagos y Facturación",
          icon: <FaMoneyCheckDollar size={28} />,
          options: [
            {
              slug: "facturacion",
              title: "Resumen general",
              route: rutas.facturacion_cliente.slug
            },
            {
              slug: "payments",
              title: "Saldo y Pagos",
              route: rutas.payments.slug
            }
          ]
        },
        {
          type: "submenu",
          slug: "integraciones",
          title: "Integraciones",
          icon: <AiOutlineCode size={28} />,
          options: [
            {
              slug: "integraciones",
              title: "Integraciones",
              route: rutas.integraciones.slug
            }
          ]
        }
      ]


      const renderMenuItem = item => {
        const activo = findActiveness(item.slug)
        if(!activo) return false
        return <MenuItem icon={item.icon} component={<Link to={item.route} />}>
          {findAlias(item.slug, item.title)}
        </MenuItem>
      }
      
      const renderButton = item => {
        const activo = findActiveness(item.slug)
        if(!activo) return false
        return <MenuItem icon={item.icon} component={<Link to={item.route} />}>
        <Button className='w-100' >{findAlias(item.slug, item.title)}</Button>
        </MenuItem>
      }
      
      const renderSubMenu = submenu => {
        const activo = findActiveness(submenu.slug)
        if(!activo) return false
        return <SubMenu icon={submenu.icon} label={findAlias(submenu.slug, submenu.title)}>
        {submenu.options.map(option => renderMenuItem(option))}
      </SubMenu>
      }
      
      const transformarAHTML = estructura => estructura.map(item =>{
        if(item.type === "submenu"){
          return renderSubMenu(item)
        } else if(item.type === "menuitem"){
          return renderMenuItem(item)
        } else if(item.type === "button"){
          return renderButton(item)
        }
      }).filter(me => me)
      
      const elementosHTML = transformarAHTML(estructura);
      const elementosHTML_CLIENTE = transformarAHTML(estructura_cliente);
      const elementosHTML_COURRIER = transformarAHTML(estructura_courrier);

      const mostrarSegunModulo = () => {
        if(modoUsuario === modos_sistema.proveedor.slug){
          return elementosHTML
        } else if(modoUsuario === modos_sistema.cliente.slug){
          return elementosHTML_CLIENTE
        } else if(modoUsuario === modos_sistema.courriers.slug){
          return elementosHTML_COURRIER
        }
      }

      const mostrarEstiloSegunModulo = () => {
        return modoUsuario === modos_sistema.proveedor.slug ? menuStyleProvider : menuItemStyleClient
      }
      
      const color_primario = modoUsuario === modos_sistema.proveedor.slug ? estilo_last_mile.color_primary : "white"
      const color_secundario = modoUsuario === modos_sistema.proveedor.slug ? "white" : estilo_last_mile.color_primary

    return <div style={{ height: "100%" }}>
        <TopBar />
        <div style={{ display: 'flex', height: '100%', minHeight: '100%' }}>

            <Sidebar collapsed={collapsed} backgroundColor={color_primario} style={{zIndex:100}}>
                <div style={{ padding: "20px 20px 0px 20px" }}>
                    <div className='hover' style={{ textAlign: collapsed ? "center" : "right", }} onClick={() => setCollapsed(!collapsed)}>
                        {!collapsed ? <div><FaArrowLeft size={20} color={color_secundario} /></div> : <BiMenu size={25} style={{ color: color_secundario, backgroundColor: "black", padding: "1px 1px", borderRadius: 5 }} />}
                    </div>
                </div>
                <Menu style={{ paddingLeft: '1px' }} menuItemStyles={mostrarEstiloSegunModulo()}>
                <MenuItem icon={<AiOutlineHome size={28} /> } component={<Link to={`/`} />}>Inicio</MenuItem>
                {mostrarSegunModulo()}
                </Menu>

                <Menu menuItemStyles={mostrarEstiloSegunModulo()}>
                    <MenuItem icon={<RiShutDownLine size={28} />} component={<div onClick={() => dispatch(cerrarSesion())} />}>Cerrar sesión</MenuItem>
                </Menu>
            </Sidebar>

            <div style={{ width: "100%", height: "100%", overflowY: "scroll" }}>{router}</div>
        </div>
    </div>
}

export default Structure