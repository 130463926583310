import { Badge, Card, Col, Row } from "react-bootstrap"
import { fechaATexto, fechaATextoSimpleCorto } from "../../lib/helpers/helpers"
import { FiMapPin } from "react-icons/fi"
import { formatoMoneda } from "../../lib/helpers/main"

const BoxList = (props) => {
    const proveedor = props.proveedor ? props.proveedor : false
    const seleccionado = props.seleccionado ? props.seleccionado : false

    if(!proveedor) return false

    const onClickProveedor = (id) => {
        if(props.onClickProveedor) return props.onClickProveedor(id)
        return false
    }

    return <Card bg={ seleccionado === true ? 'primary' : 'white' } className={`p-3 ${seleccionado === true ? 'text-white' : 'text-dark'}`} onClick={() => onClickProveedor(proveedor._id)}>
    <Row>
        <Col><p className="mb-1 text-white" style={{ padding: "1px 17px", fontSize: 13,  width: "fit-content", backgroundColor: "#848484", borderLeft:"3px solid #d96720" }}>{proveedor.forma_pago}</p></Col>
        <Col className="text-right"><h6 style={{ fontSize:10, fontWeight:'lighter' }} className="mb-2 text-right">Creado <b>{ fechaATextoSimpleCorto(proveedor.createdAt)}</b></h6></Col>
    </Row>
    <h6 style={{ fontSize: 17 }} className="mb-1">{proveedor.tipo} {proveedor.tipo === "suscripcion" ? <div className="d-inline"><Badge variant="light">{proveedor.mes}-{proveedor.ano}</Badge></div> : false}</h6>
    <h6 style={{ fontSize: 17 }} className="mb-1">${formatoMoneda(proveedor.monto)}</h6>
</Card>
}

export default BoxList