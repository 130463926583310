import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import Chart from "react-google-charts";
import { es } from 'date-fns/locale'
import { toast } from 'react-toastify';
import { urlapi } from '../../../lib/backend/data';
import { formatDate, opcionesfecha } from '../../../lib/helpers/opcionesfecha';
import { useSelector } from 'react-redux';
import { Button, Card, Col, Modal, OverlayTrigger, Row, Spinner, Table, Tooltip } from 'react-bootstrap';
import { formatoMoneda } from '../../../lib/helpers/main';
import { Bar } from 'react-chartjs-2';
import SelectorRangoFechas from '../../general/selector_rango_fechas';
import { stringByStatusMovimiento } from '../../../lib/helpers/facturacion';
import { obtenerGeoValor } from '../../../lib/helpers/pedidos/pedidos';
import SelectorServicios from '../../servicios/selector';
import SelectorClientes from '../../clientes/selector';
import { AiFillFileExcel, AiOutlineClockCircle } from 'react-icons/ai'
import * as XLSX from 'xlsx'
import { BiSolidChevronRight, BiSolidHelpCircle, BiSolidSend } from 'react-icons/bi';
import { BsArrowDownCircleFill, BsArrowUpCircleFill } from 'react-icons/bs';
import HelperModal from '../../general/helperModal';

const procesarGraficosHistorico = (datos) => {
    let datos_procesados = [[ 'Mes', 'Operaciones' ]]
    datos.map(m => {
        return datos_procesados.push([ m.name, m.operaciones ])
    })
    return datos_procesados
}

const FacturacionPendiente = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const [ ordenes, setOrdenes ] = useState( props.ordenes ? props.ordenes : false)
    const [ datos, setDatos ] = useState([])
    const [ graficosOperaciones, setGraficosOperaciones ] = useState([])
    const [ graficosProformas, setGraficosProformas ] = useState([])
    const [ graficosHistorico, setGraficosHistorico ] = useState([])
    const [ registros, setRegistros ] = useState([])
    const [ servicioFiltro, setServicioFiltro ] = useState("")
    const [ clienteFiltro, setClienteFiltro ] = useState("")
     const [ datosGeneral, setDatosGeneral ] = useState({})
    const [ loading, setLoading ] = useState(false)
    const [ loadingActualizacion, setLoadingActulizacion ] = useState(false)
    const [ errores, setErrores ] = useState([])
    const [ showModalErrores, setShowModalErrores ] = useState(false)
    const [ monto_desde, setDesde ] = useState("")
    const [ monto_hasta, setHasta ] = useState("")
    const [ showDatePicker, setShowDatePicker ] = useState(true)
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const reset_array = [...[], ...[]]

    useEffect(() => {
        setOrdenes(props.ordenes)
    })

    const warningAmount = (esperado, real) => {
        if(esperado > real) return <i className="fas text-danger fa-exclamation-circle"></i>
        return false
    }

    const actualizarMovimientos = async () => {
        
        const instancia_registros = JSON.parse( JSON.stringify( registros) )
        const movimientos = instancia_registros.map(re => {
            return {
                _id: re._id,
                nueva_tarifa: re.nueva_tarifa
            }
        })

        const url = `${urlapi}/facturacion/clientes/factucacion-pendiente`
        setLoadingActulizacion(true)
        return fetch(url, {
            method:'PUT',
            body: JSON.stringify({
                movimientos
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(pros => pros.json())
        .then(pros => {
            console.log(pros)
            if(!pros){
                toast.error('Ocurrió un error inesperado, Sin respuesta del servidor')
            } else if(pros.errorMessage){
                toast.error(`Ocurrió un error inesperado: ${pros.errorMessage}`, )
            } else if(Array.isArray(pros) !== false){
                toast.success("Registros actualizados")
                refrescarRegistros()
            }
            setLoadingActulizacion(false)
        })
        .catch(error => {
            toast.error('Ocurrió un error inesperado')
            setLoadingActulizacion(false)
        })
    }

    const refrescarRegistros = async () => {
        const desde = formatDate(selectionRange.startDate)
        const hasta = formatDate(selectionRange.endDate)
        let condicion = {}
        if(servicioFiltro) condicion.id_servicio = servicioFiltro
        if(clienteFiltro) condicion.cliente = clienteFiltro

        const ammount_from      = parseInt(monto_desde)
        const ammount_to        = parseInt(monto_hasta)

        if(ammount_from)       if(!isNaN(ammount_from))    condicion.tarifa = { $gte: ammount_from }
        if(ammount_to)         if(!isNaN(ammount_to))      condicion.tarifa = { $lte: ammount_to }

        const url = `${urlapi}/facturacion/clientes/factucacion-pendiente`
        setLoading(true)
        return fetch(url, {
            method:'POST',
            body: JSON.stringify({
                desde, hasta,
                condicion
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(pros => pros.json())
        .then(pros => {
            console.log(pros)
            if(!pros){
                toast.error('Ocurrió un error inesperado, Sin respuesta del servidor')
                return setLoading(false)
            } else if(pros.errorMessage){
                toast.error(`Ocurrió un error inesperado: ${pros.errorMessage}`, )
                return setLoading(false)
            } else if(Array.isArray(pros) !== false){
                setRegistros(pros)    
            }
            setLoading(false)
        })
        .catch(error => {
            toast.error('Ocurrió un error inesperado')
            setLoading(false)
        })
    }

    const cambiarRangoFecha = (item) => {
        console.log(item.selection)
        return setSelectionRange(item.selection)
    }

    const obtenerValorOrden = (objeto, key, default_val) => {
        let default_value = typeof default_val !== "undefined" ? default_val : ""

        if(!objeto.orden) return default_value
        if(typeof objeto.orden !== "object") return default_value
        return objeto.orden[key] 
    }

    const mostrarServicio = (servicio) => {
        if(!servicio) return "Sin información"
        return servicio.nombre
    }

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
          view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
      };

    const handleDownloadExcel = ({cabeceras,data}) => {
        // Crear el archivo de Excel
        const wb = XLSX.utils.book_new();
        const wsData = [cabeceras]
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');
        XLSX.utils.sheet_add_aoa(ws, data, { origin: -1 });
        // Convertir el libro de trabajo a un archivo binario
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    
        // Crear un Blob con los datos binarios
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    
        // Generar un objeto URL del Blob
        const url = URL.createObjectURL(blob);
    
        // Crear un enlace de descarga invisible y hacer clic en él
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Modelo Tarifario.xlsx';
        a.click();
    
        // Liberar el objeto URL
        URL.revokeObjectURL(url);
    }

    const descargarModelo = () => {

        const iterar_datos = registros.map(re => {

            let objeto = {
                id: re._id,
                servicio: mostrarServicio(re.servicio),
                tarifa: re.tarifa,
                nivel_admin_1: obtenerGeoValor(re.orden, "level1"),
                nivel_admin_2: obtenerGeoValor(re.orden, "level2"),
                peso: obtenerValorOrden(re, "peso", 0),
                volumen: obtenerValorOrden(re, "volumen", 0),
            }
            return [ objeto.id, objeto.servicio, objeto.tarifa, objeto.nivel_admin_1, objeto.nivel_admin_2, objeto.peso, objeto.volumen ]
        })

        const estructura_excel = {
            cabeceras: ["ID","Servicio","Tarifa","Nivel administrativo 1","Nivel administrativo 2","Peso","Volumen"],
            data: iterar_datos
        }

        return handleDownloadExcel({ cabeceras: estructura_excel.cabeceras, data: estructura_excel.data })
    }

    const handleInputChange = (event) => {

        const target = event.target
        const name = target.name
        let hojas = []
        setErrores(reset_array)

        if (name === 'file') {

            let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })

            if(!hojas[0]) return toast.error('No se encontraron datos', )
            if(Array.isArray(hojas[0]) !== true) return toast.error('No se encontraron datos', )
            if(hojas[0].length < 1) return toast.error('No se encontraron datos', )
            
            let problemas = []
            let tarifas = []
            
            const instancia_registros = JSON.parse( JSON.stringify( registros) )
            
            hojas[0].map((row,irow) => {

                if(!row.ID){
                    problemas.push(`Fila ${(irow+1)} sin ID de tarifa`)
                    return false
                }
                
                if(typeof row.Tarifa !== "undefined"){
                    const monto = parseFloat(row.Tarifa)
                    if(!isNaN(monto)){
                        const i = instancia_registros.findIndex(re => re._id === row.ID)
                        if(i > -1 ){
                            instancia_registros[i].nueva_tarifa = row.Tarifa
                            instancia_registros[i].editado = true
                        } else {
                            problemas.push(`Fila ${(irow+1)} ID no encontrado para actualización`)
                        }
                    } else {
                        problemas.push(`Fila ${(irow+1)} con tarifa no válida`)
                    }
                }


            })
            
            if(problemas.length > 0){
                setErrores([...[], ...problemas])
                return toast.error(`Se detectaron problemas en tu archivo`, ) // SI HAY ERRORES DETENER
            }
            console.log(instancia_registros, problemas)
            return setRegistros([...[], ...instancia_registros])
          }
        }
      }

      const handleClose = () => {
        return setShowModalErrores(false)
    }

      const mostrarErrores = () => {
        if(errores.length < 1) return false
        return <div className="mt-3">
            <Card bg="danger" className="p-2 text-white">
            <h6 className="mb-0">Hay {errores.length} errores detectados <Button size="sm" variant="link text-white" onClick={() => setShowModalErrores(true)}><BiSolidChevronRight size={20} /> <b>VER PROBLEMAS</b></Button></h6>
            </Card>
            <Modal show={showModalErrores} size="lg" onHide={handleClose} centered >
                <Modal.Header closeButton>
                <Modal.Title>Errores detectados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {
                    errores.map((error,irr) => {
                        return <p className="mb-1" key={`error-${irr}`}><BiSolidChevronRight size={20} /> {error}</p>
                    })
                   }
                </Modal.Body>
            </Modal>
        </div>
    }

    const calcularPorcentaje = (valorAnterior, valorActual) => {
        if (valorAnterior === 0) {
          // Manejar el caso especial si el valor anterior es 0 para evitar la división por cero.
          if (valorActual > 0) {
            return 0
          } else if (valorActual < 0) {
            return 0
          } else {
            return 0
          }
        }
      
        const cambioPorcentual = ((valorActual - valorAnterior) / Math.abs(valorAnterior)) * 100;
        return cambioPorcentual.toFixed(2)
      };

    const accionesSubidaRegistros = () => {
        const cantidad_para_actualizar = registros.filter(r => r.editado === true)
        if(cantidad_para_actualizar.length < 1) return false

        const instancia_registros = JSON.parse( JSON.stringify( cantidad_para_actualizar ) )

        const total_actual = instancia_registros.reduce((prev,next) => {
            return prev + next.tarifa
        },0)
        
        const total_nuevo = instancia_registros.reduce((prev,next) => {
            return prev + next.nueva_tarifa
        },0)

        const porcentaje = calcularPorcentaje(total_actual, total_nuevo)
        const positivo = porcentaje < 0 ? false : true

        return <div className='mb-3'>
            <Row className='mb-3'>
                <Col md={3}>
                    <Card className='p-2'>
                    <OverlayTrigger
                        placement={'top'}
                        overlay={
                            <Tooltip>Total de los registros que subiste antes de los cambios</Tooltip>
                        }
                        ><div>
                        <p className='mb-0'>Total actual <BiSolidHelpCircle /></p>    
                        </div>
                    </OverlayTrigger>
                        <h3>{formatoMoneda(total_actual)}</h3>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className='p-2'>
                    <OverlayTrigger
                        placement={'top'}
                        overlay={
                            <Tooltip>Total que cargaste en tu archivo de actualización</Tooltip>
                        }
                        ><div>
                        <p className='mb-0'>Total nuevo <BiSolidHelpCircle /></p>    
                        </div>
                    </OverlayTrigger>
                        <h3>{formatoMoneda(total_nuevo)}</h3>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className={`p-2`}>
                        <p className='mb-0'>Porcentaje</p>
                        <h3 className={`${positivo ? 'text-success' : "text-danger"}`}>{porcentaje}% {positivo ? <BsArrowUpCircleFill size={15} /> : <BsArrowDownCircleFill size={15} /> }</h3>
                    </Card>
                </Col>
            </Row>
            <Button disabled={loadingActualizacion} variant='success' size="sm" onClick={() => actualizarMovimientos()} ><BiSolidSend /> {loadingActualizacion ? `ACTUALIZANDO...` : `ACTUALIZAR ${cantidad_para_actualizar.length} REGISTROS`} </Button>
        </div>
    }

    const mostrarRegistros = () => {
        if(registros.length < 1) return <HelperModal tipo='facturacion' style='nodata'/>

        const total = registros.reduce((prev,next) => {
            try {
                let monto = 0
                if(next.tarifa) if(!isNaN(next.tarifa)) monto = next.tarifa
                return prev + monto
            } catch (error) {
                return prev
            }
        }, 0)
        
        return <div>
            {mostrarErrores()}
            <Row>
                <Col md={2} className='mb-3'>
                    <Card className='p-2'>
                        <label className='form-control-label d-block mb-0'>Total</label>
                        <h4 className='mb-0'>{formatoMoneda(total)}</h4>
                    </Card>
                </Col>
            </Row>
            <Card className='p-3'>
            <h4>{registros.length} Registros</h4>
            <Row className='mb-3'>
                <Col md={12}>
                    <label className="form-control-label d-block">Descarga los registros y luego actualizalos cargando de nuevo</label>
                    <Button className='w-100' variant="light" size="sm" onClick={() => descargarModelo()}><AiFillFileExcel /> DESCARGAR EXCEL DE TARIFAS</Button>
                </Col>
                {/* <Col md={6}>
                <label className="form-control-label d-block">Subir archivo excel</label>
                    <input  
                            type="file" 
                            name="file" 
                            id="file" 
                            className="form-control"
                            onChange={handleInputChange} 
                            placeholder="Archivo de excel" 
                            />
                </Col> */}
            </Row>

            {accionesSubidaRegistros()}

            <Table className='mb-0'>
                <thead>
                    <tr>
                        <th>REF</th>
                        <th>ZONA 1</th>
                        <th>ZONA 2</th>
                        <th>SERVICIO</th>
                        <th>TARIFA</th>
                        <th>PESO</th>
                        <th>VOLUMEN</th>
                        <th>ESTADO</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        registros.map((reg,ir) => {
                            return <tr key={`reg-${ir}`}>
                                <th>{reg.identificador}
                                {reg.editado === true ? <div className='text-warning'><AiOutlineClockCircle /> PENDIENTE DE GUARDAR</div> : false}
                                </th>
                                <th>{obtenerGeoValor(reg.orden, "level1")}</th>
                                <th>{obtenerGeoValor(reg.orden, "level2")}</th>
                                <th>{mostrarServicio(reg.servicio)}</th>
                                <th>{formatoMoneda(reg.tarifa)}</th>
                                <th>{reg.orden.peso}</th>
                                <th>{reg.orden.volumen}</th>
                                <th>{stringByStatusMovimiento(reg.status)}</th>
                            </tr>
                        })
                    }
                </tbody>
            </Table>
            </Card>
        </div>
    }

    const onChangeServicio = (e) => {
        const { value, label } = e
        return setServicioFiltro(value)
    }
    
    const onChangeCliente = (e) => {
        console.log(e.rut)
        return setClienteFiltro(e.rut)
    }
    const handleChangeDesde = (e) => {
        const { value } = e.target
        return setDesde(value)
    }

    const handleChangeHasta = (e) => {
        const { value } = e.target
        return setHasta(value)
    }

    const mostrarDatePicker = () => {
        if(showDatePicker !== true) return <button className='btn btn-sm mb-3 btn-outline-warning' onClick={()=> setShowDatePicker(true) }>Seleccionar fechas</button>

        return <div>
            <button className='btn btn-sm mb-3 btn-outline-warning d-block' onClick={()=> setShowDatePicker(false) }>Ocultar selector fechas</button>
            <DateRangePicker
                locale={es}
                showSelectionPreview={true}
                editableDateInputs={true}
                onChange={item => cambiarRangoFecha(item)}
                moveRangeOnFirstSelection={false}
                ranges={[selectionRange]}
                direction="horizontal"
                scroll={{ enabled: true }}
                months={2}
            />
        </div>
    }

    let rangoactual = ''

    if(selectionRange.startDate){
        const stringdesde = selectionRange.startDate.toLocaleDateString('es-ES', opcionesfecha)
        rangoactual += `Desde ${stringdesde}`
    }

    if(selectionRange.endDate){
        const stringhasta = selectionRange.endDate.toLocaleDateString('es-ES', opcionesfecha)
        rangoactual += ` hasta ${stringhasta}`
    }

    if(selectionRange.startDate === selectionRange.endDate){
        const stringunicafecha = selectionRange.startDate.toLocaleDateString('es-ES', opcionesfecha)
        rangoactual = ''
        rangoactual = stringunicafecha
    }

    return <div>
    <Row >
        <Col md={12} className='mb-3'><SelectorRangoFechas onChange={item => cambiarRangoFecha(item)} /></Col>
        {/* <Col md={6} className='mb-1'><SelectorServicios onChange={data => onChangeServicio(data)} titulo="Servicio" /></Col> */}
        {/* <Col md={6} className='mb-1'><SelectorClientes full_payload={true} onChange={data => onChangeCliente(data)} titulo="Cliente" /></Col> */}
        <Col md={2}>
            <label className='form-control-label d-block' style={{fontWeight:700}}>Monto desde</label>
            <input className='form-control shadow-sm' type="number" onChange={handleChangeDesde} />
        </Col>
        <Col md={2}>
            <label className='form-control-label d-block' style={{fontWeight:700}}>Monto hasta</label>
            <input className='form-control shadow-sm' type="number" onChange={handleChangeHasta} />
        </Col>
        <Col md={3}>
            <label className='form-control-label d-block' style={{fontWeight:700}}>Estado de carga</label>
            <select className='form-control shadow-sm'>
                <option value="">Cualquiera</option>
            </select>
        </Col>
        <Col md={5} className='row align-items-end pr-0'>
            {/* <label className='form-control-label d-block'>Click para consultar</label> */}
            {loading === true ? <Spinner animation='border' /> : <button className='btn btn-primary shadow-sm d-block w-100 ' onClick={()=>refrescarRegistros()}><b>CONSULTAR</b></button>}
        </Col>
    </Row>
    {mostrarRegistros()}
    </div>
}

export default FacturacionPendiente