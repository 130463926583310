import { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Card, Table, OverlayTrigger, Tooltip, Accordion } from 'react-bootstrap'
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { cerrarSesion } from '../../redux/actions/session';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { formatoMoneda } from '../../lib/helpers/main';
import GraficoRecursos from './grafico_recursos';
import HelperModal from '../general/helperModal';
import { main_module_titles, main_modules } from '../general/data';
import { IoIosHelpCircle } from 'react-icons/io';

const ConteosListado = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const dispatch = useDispatch()
    const [ loadingConductores, setLoadingConductores ] = useState(true)
    const ahora = DateTime.now();
    const mesActual = ahora.toFormat('MM');
    const anoActual= ahora.toFormat('yyyy');
    const [mes, setMes] = useState(mesActual)
    const [ano, setAno] = useState(anoActual)
    const [propietario, setPropietario] = useState(props.propietario)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState({propietario, fecha: {mes, ano}})
    const [conteo, setConteo] = useState({})
    const [ costos, setCostos ] = useState([])
    const token = props.token ? props.token : false
    const trash = props.trash ? props.trash : false

    const descripciones = {
        "api_mapbox-optimization": {
            description: "Se usa para optimizar una ruta",
        },
        "api_mapbox-get-optimization": {
            description: "Se usa para consultar una optimización de ruta",
        },
        "api_mapbox-routing": {
            description: "Se usa para consultar posibles rutas desde coordenadas",
        },
        "api_google_maps-geocode": {
            description: "Se usa para consultar datos geográficos",
        },
        "api_google_maps-place-photo": {
            description: "Se usa para obtener fotos de un lugar",
        },
        "api_google_maps-place-details": {
            description: "Se usa para consultar detalles de un lugar",
        },
        "enviar_emails-msg": {
            description: "Se usa para enviar un email",
        },
        "enviar_whatsapp-msg": {
            description: "Se usa para enviar un whatsapp",
        },
    }


    const obtenerConteos = async ()=>{
        setLoadingConductores(true)
       const condicion = { propietario, fecha: { mes: mes.toString(), ano: ano.toString()}}
        return fetch(`${data.urlapi}/conteo/recursos/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(typeof res === 'object'){
                setConteo(res)
                if(res.costos) if(Array.isArray(res.costos)) setCostos(res.costos)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }


    useEffect(() => {
        obtenerConteos()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const tabla_registros = (conteo) => {
        if(loadingConductores===true) return cargandoScreen()
        if(!conteo) return  <div>
            <h5>Nada por aquí</h5>
            <p>No hay registros en esta sección</p>
        </div>

        const sumar = Object.keys(conteo).filter(key => !['propietario','fecha','mes','ano'].includes(key)).map((key) => conteo[key]).filter(num => parseInt(num)).reduce((a, b) => a + b, 0)   

        let grupos = []

        for( const costo of costos ){
            const i = grupos.findIndex(g => g.slug === costo.slug)
            if(i > -1){

                grupos[i].sub_modulos.push({
                    sub_api: costo.sub_api,
                    nombre: costo.nombre,
                    costo: costo.costo,
                    solicitudes: costo.solicitudes,
                })

            } else {
                grupos.push({
                    slug: costo.slug,
                    sub_modulos: [
                        {
                            sub_api: costo.sub_api,
                            nombre: costo.nombre,
                            costo: costo.costo,
                            solicitudes: costo.solicitudes,
                        }
                    ]
                })
            }
        }

        return <div>
            {/* <h5 className='mb-0'>TOTAL</h5>
            <h4 className='mb-0'>${formatoMoneda(sumar)}</h4>
            <p style={{ fontSize: 10 }}>Total de gasto</p> */}
            <h5><b>Total de inversión</b></h5>
            <h3 className='mb-3'>${formatoMoneda(costos.reduce((prev,next) => prev + next.costo, 0))}</h3>

            <h5><b>Costos por API + Servicio</b></h5>

            <Accordion className='mb-3'>
                {
                    grupos.map((grupo,ig) => {

                        const titulos = {}
                        main_module_titles.map(e => titulos[e.slug] = e.nombre)

                        return <Card className="p-0" key={`g-${ig}`} >
                        <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor: 'white' }} eventKey={grupo.slug}>
                            <div className="pl-3 pr-3 pb-0">
                                <h6 className='mb-0'>{titulos[grupo.slug] ? titulos[grupo.slug] : grupo.slug}</h6>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={grupo.slug}>
                            <Card.Body style={{ backgroundColor: '#f1f1f1' }}>
                                <Row>
                               {
                                grupo.sub_modulos.map((submodulo,isub) => {
                                    return <Col md={4} key={`sub-modulo-${ig}-${isub}`}>
                                        <h6>{submodulo.nombre} <OverlayTrigger
                                            overlay={<Tooltip id={`tooltip-${isub}`}>{descripciones[`${grupo.slug}-${submodulo.sub_api}`]?.description}</Tooltip>}
                                        ><IoIosHelpCircle /></OverlayTrigger></h6>
                                        <h2>${formatoMoneda(submodulo.costo)}</h2>
                                        <p style={{ fontSize: 12, fontWeight: "bold" }}><b>{submodulo.solicitudes ? formatoMoneda(submodulo.solicitudes) : 0} solicitudes</b></p>
                                    </Col>
                                })
                               }
                               </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    })
                }
            </Accordion>


            <h5><b>Datos por cantidad de solicitudes</b></h5>
            <Table bordered className='table'>
                    <thead>
                        <tr>
                            <th style={{ position: 'relative', paddingBlock: 0 }}>
                            <HelperModal style='modal' tipo='mapbox' size='small'/>
                            Api Mapbox
                            </th>
                            <th style={{ position: 'relative', paddingBlock: 0 }}>
                            <HelperModal style='modal' tipo='googleMaps' size='small'/>
                            Api Google Maps
                            </th>
                            <th style={{ position: 'relative', paddingBlock: 0 }}>
                            <HelperModal style='modal' tipo='emails' size='small'/>
                            Emails enviados
                            </th>
                            <th style={{ position: 'relative', paddingBlock: 0 }}>
                            <HelperModal style='modal' tipo='whatsapp_sent' size='small'/>
                            Whatsapp enviados
                            </th>
                            <th>Órdenes creadas</th>
                            <th>Conductores propios activos</th>
                            <th>Conductores externos activos</th>
                            <th>Proveedores lastmile usados</th>
                            <th>Detecciones de geocercas</th>
                        </tr>
                    </thead>
                    <tbody>

                    
            {
                        
                                     <tr >
                                        <th><h4>{conteo?.api_mapbox ? formatoMoneda(conteo?.api_mapbox) : 0}</h4></th>
                                        <th><h4>{conteo?.api_google_maps ? formatoMoneda(conteo.api_google_maps) : 0}</h4></th>
                                        <th><h4>{conteo?.enviar_emails ? formatoMoneda(conteo.enviar_emails) : 0}</h4></th>
                                        <th><h4>{conteo?.enviar_whatsapp ? formatoMoneda(conteo.enviar_whatsapp) : 0}</h4></th>
                                        <th><h4>{conteo?.orders_created ? formatoMoneda(conteo.orders_created) : 0}</h4></th>
                                        <th><h4>{conteo?.drivers_actives ? formatoMoneda(conteo.drivers_actives) : 0}</h4></th>
                                        <th><h4>{conteo?.drivers_actives_external ? formatoMoneda(conteo.drivers_actives_external) : 0}</h4></th>
                                        <th><h4>{conteo?.lastmile_providers_used ? formatoMoneda(conteo.lastmile_providers_used) : 0}</h4></th>
                                        <th><h4>{conteo?.geofencing_alerts ? formatoMoneda(conteo.geofencing_alerts) : 0}</h4></th>
                                    </tr>
                    
            }
            </tbody>
            </Table>

        </div>
    }


    const handleChangeMes = (e) => {
        const {name, value} = e.target
        console.log(value)
       return setMes(value)

    }
    const handleChangeAno = (e) => {
        const {name, value} = e.target
        console.log(value)
       return setAno(value)
    }
 
    return <div>
        {/* {modalFiltro()} */}
        
        <Row>
            <Col md={12}>
            <h4><b>Conteo de recursos</b></h4>
                <Row>
                    <Col md={4}>
                        <label className='form-control-label d-block'>Seleccionar Mes</label>
                        <select className='form-control mb-4' name="mes" defaultValue={mes} onChange={(e)=>handleChangeMes(e)} >
                            {/* <option value="" >Seleccione</option> */}
                            <option value="01" >Enero</option>
                            <option value="02" >Febrero</option>
                            <option value="03" >Marzo</option>
                            <option value="04" >Abril</option>
                            <option value="05" >Mayo</option>
                            <option value="06" >Junio</option>
                            <option value="07" >Julio</option>
                            <option value="08" >Agosto</option>
                            <option value="09" >Septiembre</option>
                            <option value="10" >Octubre</option>
                            <option value="11" >Noviembre</option>
                            <option value="12" >Diciembre</option>
                          
                        </select>
                        </Col>
                    <Col md={4}>
                        <label className='form-control-label d-block'>Seleccionar Año</label>
                        <select className='form-control mb-4' name="ano" defaultValue={ano} onChange={(e)=>handleChangeAno(e)} >
                            {/* <option value="" >Seleccione</option> */}
                            <option value="2024" >2024</option>
                            <option value="2023" >2023</option>                          
                        </select>
                    </Col>
                    <Col md={4}>
                    <Button className='mt-4' onClick={()=>obtenerConteos()}>ACTUALIZAR</Button>
                    </Col>
                    <Col md={12}>
                    {tabla_registros(conteo)}
                    </Col>
                </Row>
            </Col>
        </Row>
        <hr className='mt-2 mb-2' />
        <GraficoRecursos propietario={propietario} />
    </div>

}

export default ConteosListado