export const main_module_titles = [
    {
        nombre: "Api Mapbox",
        slug: "api_mapbox",
    },
    {
        nombre: "Api Google Maps",
        slug: "api_google_maps",
    },
    {
        nombre: "Api Sendgrid",
        slug: "enviar_emails",
    },
    {
        nombre: "Api Whatsapp",
        slug: "enviar_whatsapp",
    }
]

export const main_modules = [
    {
        nombre: "Api Mapbox - Ruteo",
        slug: "api_mapbox",
        sub_api: "optimization",
        precio: 0
    },
    {
        nombre: "Api Mapbox - Consulta del ruteo",
        slug: "api_mapbox",
        sub_api: "get-optimization",
        precio: 0
    },
    {
        nombre: "Api Mapbox - Directions",
        slug: "api_mapbox",
        sub_api: "routing",
        precio: 0
    },
    {
        nombre: "Api Google - geolocalización",
        slug: "api_google_maps",
        sub_api: "geocode",
        precio: 0
    },
    {
        nombre: "Api Google - Places Api de Fotos",
        slug: "api_google_maps",
        sub_api: "place-photo",
        precio: 0
    },
    {
        nombre: "Api Google - Places",
        slug: "api_google_maps",
        sub_api: "place-details",
        precio: 0
    },
    {
        nombre: "Api Sendgrid - Mailing",
        slug: "enviar_emails",
        sub_api: "msg",
        precio: 0
    },
    {
        nombre: "Api Whatsapp - mensajes",
        slug: "enviar_whatsapp",
        sub_api: "msg",
        precio: 0
    }
]