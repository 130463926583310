import React, { useState, useRef, useEffect } from "react";
import { Button, Card, Col, Row,  Spinner} from "react-bootstrap";
import { toast } from "react-toastify";
import { urlapi } from "../../lib/backend/data";
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import QRCode from 'qrcode.react';
import { useReactToPrint } from 'react-to-print';
import Select from 'react-select'
const Barcode = require('react-barcode');

const MostrarEtiquetasPersonalizadas = React.forwardRef((props, ref) => {
  const {
    filtros
  } = props
  const [ opcionesSeleccionadas, setOpcionesSeleccionadas ] = useState([])
  const [ tipoFiltro, setTipoFiltro ] = useState('')
  const [ tiposBusqueda, setTiposBusqueda ] = useState([])

  useEffect(() => {

    let items = []
    for( const tipo of filtros ){
      items.push({ value: tipo.value, label: tipo.label })
    }
    if(items.length > 0) setTipoFiltro(items[0].value)
    setTiposBusqueda(items)

  }, [ props.etiquetas ])

  const mostrarFiltros = () => {
    if(!filtros) return false
    if(!Array.isArray(filtros)) return false
    if(filtros.length < 1) return false

    return <div className="mb-3">
      <h4 className="mb-0"><b>Filtrar etiquetas</b></h4>
      <Row>
        <Col md={4}>
          <label className="form-control-label d-block">Tipo de búsqueda</label>
          <select className="form-control" onChange={(e) => setTipoFiltro(e.target.value)}>
            { tiposBusqueda.map((op) => <option value={op.value}>{op.label}</option> ) }
          </select>
        </Col>
        {
          filtros.map((filter,i) => {
            if(tipoFiltro !== filter.value) return false
            const options = filter.options.map(op => ({ value: op, label: op }))

            return <Col key={`filtro-${i}`}>
              <label className="form-control-label d-block">{filter.label}</label>
              <Select key={`${filter.value}`} placeholder="Selecciona" isClearable isMulti options={options} onChange={e => {
                setTipoFiltro(filter.value)
                setOpcionesSeleccionadas(e)
              }} />
            </Col>
          })
        }
      </Row>
    </div>
  }

  if(!props.etiquetas) return false
  if(props.etiquetas.length < 1) return false
  if(!props.personalizacionEtiqueta) return false

  let labels = opcionesSeleccionadas.length > 0 ? props.etiquetas.filter(e => {
    const campo = e[tipoFiltro] ? e[tipoFiltro].toString().toLowerCase() : ""
    if(!campo) return false
    return opcionesSeleccionadas.map(op => op.value).includes(campo)
  }).sort((a,b) => a[tipoFiltro] < b[tipoFiltro]) : props.etiquetas

  return <div>
    {mostrarFiltros()}
    <h5 className="mb-0" style={{ textTransform: "uppercase" }}><b>{labels.length} Etiquetas</b></h5>
    <div className='' ref={ref} >
    {
      labels.map((etiqueta,i) => {
        const cantidad_total = props.etiquetas.filter(e => e.idrecurso === etiqueta.idrecurso).length
        return <>
          <div className="etiquetabodega" key={`etiqueta-${etiqueta._id}`} style={{ width: '100%'}}>
            <div style={{ textAlign: "center", padding: 5 }}>
              {props.personalizacionEtiqueta ? mostrarLogotipo(props.personalizacionEtiqueta) : false}            
              {props.personalizacionEtiqueta ? mostrarTextoLogo(props.personalizacionEtiqueta) : false}     
              {props.personalizacionEtiqueta ? mostrarId(etiqueta, props.personalizacionEtiqueta) : false}            
              {props.personalizacionEtiqueta ? mostrarQr(etiqueta, props.personalizacionEtiqueta) : false}      
              {etiqueta[tipoFiltro]}      
             
            </div>
            <div style={{ textAlign: "center" }}>
            {props.personalizacionEtiqueta ? mostrarReferencia(etiqueta, props.personalizacionEtiqueta) : false}
            {props.personalizacionEtiqueta ? mostrarTelefono(etiqueta, props.personalizacionEtiqueta) : false}
            {props.personalizacionEtiqueta ? mostrarDireccion(etiqueta, props.personalizacionEtiqueta) : false}         
            {props.personalizacionEtiqueta ? mostrarProducto(etiqueta, props.personalizacionEtiqueta) : false}         
            {props.personalizacionEtiqueta ? mostrarBultos(etiqueta, props.personalizacionEtiqueta, cantidad_total) : false} 
            {props.personalizacionEtiqueta ? mostrarTextoResumen(props.personalizacionEtiqueta) : false}
            {props.personalizacionEtiqueta ? mostrarCodigoBarras(etiqueta, props.personalizacionEtiqueta) : false}     
             
            </div>
          { i < props.etiquetas.length -1 ? <div className="page-break"></div> : false }
        </div>
        </>
      })
    }
  </div>
  </div>
})

const mostrarLogotipo = (personalizacionEtiqueta) => {
  if(!personalizacionEtiqueta?.detalles?.modulos) return false
  const obj = personalizacionEtiqueta.detalles.modulos.find(per => per.slug === "logotipo")
  if(!obj) return false
  if(!obj?.imagen) return <img src="/images/logo.png" style={{ width: '40%' }} />
  return <img src={obj.imagen} style={{ width: '40%' }} />
  } 


const mostrarExtra = (obj) => {
  if(!obj?.extra) return false 
  return <p className="mb-1" style={{ fontSize: 16, fontWeight: "bold" }}>{obj.extra}</p>
  }
  

const mostrarId = (etiqueta, personalizacionEtiqueta) => {
  if(!etiqueta?.id) return false
  if(!personalizacionEtiqueta?.detalles?.campos) return false
  const obj = personalizacionEtiqueta.detalles.campos.find(per => per.slug === "id")
  if(!obj) return false
  if(obj.activo) return <h4><b>ID</b>: {etiqueta.id}</h4>
}

const mostrarQr = (etiqueta, personalizacionEtiqueta) => {
  if(!etiqueta?._id) return false
  if(!personalizacionEtiqueta?.detalles?.modulos) return false
  const obj = personalizacionEtiqueta.detalles.modulos.find(per => per.slug === "qr")
  if(!obj) return false
  if(obj.activo) return <div className="qr" style={{  margin: '0 auto' }}><QRCode value={etiqueta._id} renderAs="svg" />
  {mostrarExtra(obj)}</div>
}

const mostrarTextoResumen = (personalizacionEtiqueta) => {
  if(!personalizacionEtiqueta?.detalles?.modulos) return false
  const obj = personalizacionEtiqueta.detalles.modulos.find(per => per.slug === "resumen")
  if(!obj) return false
  if(obj.activo) {
    if(obj.extra) return <p className="mb-1" style={{ fontSize: 16, fontWeight: "bold" }}>{obj.extra}</p>
  } 
}

const mostrarTextoLogo = (personalizacionEtiqueta) => {
  if(!personalizacionEtiqueta?.detalles?.modulos) return false
  const obj = personalizacionEtiqueta.detalles.modulos.find(per => per.slug === "logotipo")
  if(!obj) return false
  if(obj.activo) {
    if(obj.extra) return <p className="mb-1" style={{ fontSize: 16, fontWeight: "bold" }}>{obj.extra}</p>
  } 
}

const mostrarReferencia = (etiqueta, personalizacionEtiqueta) => {
  if(!etiqueta?.identificador) return false
  if(!personalizacionEtiqueta?.detalles?.campos) return false
  const obj = personalizacionEtiqueta.detalles.campos.find(per => per.slug === "referencia")
  if(!obj) return false
  if(obj.activo) return <h3>REF: {etiqueta.identificador}</h3>
}

const mostrarTelefono = (etiqueta, personalizacionEtiqueta) => {
  if(!etiqueta?.destinatario) return false
  if(!etiqueta?.telefono) return false
  if(!personalizacionEtiqueta?.detalles?.campos) return false
  const obj = personalizacionEtiqueta.detalles.campos.find(per => per.slug === "telefono")
  if(!obj) return false
  if(obj.activo) return <div> {etiqueta.destinatario ? <p className="mb-0" style={{ fontSize: 25, fontWeight: "bold" }} >{etiqueta.destinatario} { etiqueta.telefono ? <b>TELF. {etiqueta.telefono}</b> : false }</p> : false }</div>
}

const mostrarDireccion = (etiqueta, personalizacionEtiqueta) => {
  if(!etiqueta?.direccion) return false
  if(!personalizacionEtiqueta?.detalles?.campos) return false
  const obj = personalizacionEtiqueta.detalles.campos.find(per => per.slug === "direccion")
  if(!obj) return false
  if(obj.activo) return <div>{etiqueta.direccion ? <p className="mb-0" style={{ fontSize: 25, fontWeight: "bold" }} >{etiqueta.direccion}</p> : false }</div>
}

const mostrarProducto = (etiqueta, personalizacionEtiqueta) => {
  if(!etiqueta?.note) return false
  if(!personalizacionEtiqueta?.detalles?.campos) return false
  const obj = personalizacionEtiqueta.detalles.campos.find(per => per.slug === "producto")
  if(!obj) return false
  if(obj.activo) return <div>{ etiqueta.note ? <h3>{etiqueta.note}</h3> : false }</div>
}

const mostrarBultos = (etiqueta, personalizacionEtiqueta, cantidad_total) => {
  if(!etiqueta?.numero) return false
  if(cantidad_total) return false
  if(!personalizacionEtiqueta?.detalles?.campos) return false
  const obj = personalizacionEtiqueta.detalles.campos.find(per => per.slug === "bulto")
  if(!obj) return false
  if(obj.activo) return <h3>BULTO {etiqueta.numero} de {cantidad_total}</h3>
  }

const mostrarCodigoBarras = (etiqueta, personalizacionEtiqueta, cantidad_total) => {
  if(!etiqueta?._id) return false
  if(cantidad_total) return false
  if(!personalizacionEtiqueta?.detalles?.modulos) return false
  const obj = personalizacionEtiqueta.detalles.modulos.find(per => per.slug === "barcode")
  if(!obj) return false
  if(obj.activo) return <div><h3>Tracking ID</h3>
<div className="barcode-etiqueta"><Barcode height={80} value={etiqueta._id} /></div>
{mostrarExtra(obj)}</div>
}

const MostrarEtiquetas = React.forwardRef((props, ref) => {
  const {
    filtros
  } = props
  const [ opcionesSeleccionadas, setOpcionesSeleccionadas ] = useState([])
  const [ tipoFiltro, setTipoFiltro ] = useState('')
  const [ tiposBusqueda, setTiposBusqueda ] = useState([])

  useEffect(() => {

    let items = []
    for( const tipo of filtros ){
      items.push({ value: tipo.value, label: tipo.label })
    }
    if(items.length > 0) setTipoFiltro(items[0].value)
    setTiposBusqueda(items)

  }, [ props.etiquetas ])

  const mostrarFiltros = () => {
    if(!filtros) return false
    if(!Array.isArray(filtros)) return false
    if(filtros.length < 1) return false

    return <div className="mb-3">
      <h4 className="mb-0"><b>Filtrar etiquetas</b></h4>
      <Row>
        <Col md={4}>
          <label className="form-control-label d-block">Tipo de búsqueda</label>
          <select className="form-control" onChange={(e) => setTipoFiltro(e.target.value)}>
            { tiposBusqueda.map((op) => <option value={op.value}>{op.label}</option> ) }
          </select>
        </Col>
        {
          filtros.map((filter,i) => {
            if(tipoFiltro !== filter.value) return false
            const options = filter.options.map(op => ({ value: op, label: op }))

            return <Col key={`filtro-${i}`}>
              <label className="form-control-label d-block">{filter.label}</label>
              <Select key={`${filter.value}`} placeholder="Selecciona" isClearable isMulti options={options} onChange={e => {
                setTipoFiltro(filter.value)
                setOpcionesSeleccionadas(e)
              }} />
            </Col>
          })
        }
      </Row>
    </div>
  }

  if(!props.etiquetas) return false
  if(props.etiquetas.length < 1) return false

  let labels = opcionesSeleccionadas.length > 0 ? props.etiquetas.filter(e => {
    const campo = e[tipoFiltro] ? e[tipoFiltro].toString().toLowerCase() : ""
    if(!campo) return false
    return opcionesSeleccionadas.map(op => op.value).includes(campo)
  }).sort((a,b) => a[tipoFiltro] - b[tipoFiltro]) : props.etiquetas
  
  return <div>
    {mostrarFiltros()}
    <h5 className="mb-0" style={{ textTransform: "uppercase" }}><b>{labels.length} Etiquetas</b></h5>
    <div className='' ref={ref} >
        {
          labels.map((etiqueta,i) => {
            const cantidad_total = props.etiquetas.filter(e => e.idrecurso === etiqueta.idrecurso).length
            return <>
              <div className="etiquetabodega" key={`etiqueta-${etiqueta._id}`} style={{ width: '100%' }}>
                <div style={{ textAlign: "center", padding: 5 }}>
                  <img src="/images/logo.png" style={{ width: '40%' }} />
                  { etiqueta.id ? <h4><b>ID</b>: {etiqueta.id}</h4> : false }
                  <div className="qr" style={{  margin: '0 auto' }}><QRCode value={etiqueta._id} renderAs="svg" /></div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <h3>REF: {etiqueta.identificador}</h3>
                  {etiqueta.destinatario ? <p className="mb-0" style={{ fontSize: 25, fontWeight: "bold" }} >{etiqueta.destinatario} { etiqueta.telefono ? <b>TELF. {etiqueta.telefono}</b> : false }</p> : false }
                  {etiqueta.direccion ? <p className="mb-0" style={{ fontSize: 25, fontWeight: "bold" }} >{etiqueta.direccion}</p> : false }
                  { etiqueta.note ? <h3>{etiqueta.note}</h3> : false }
                  <h3>BULTO {etiqueta.numero} de {cantidad_total}</h3>
                  <h3>Tracking ID</h3>
                  <div className="barcode-etiqueta"><Barcode height={80} value={etiqueta._id} /></div>
                </div>
              { i < props.etiquetas.length -1 ? <div className="page-break"></div> : false }
            </div>
            </>
          })
        }
      </div>
  </div>
})



const Etiquetas = (props) => {
  const [personalizacionEtiqueta, setPersonalizacionEtiqueta] = useState({})
  const [ loadingEtiquetas, setLoadingEtiquetas ] = useState(false)
  const dispatch = useDispatch()
  const session = useSelector(state => state.miusuario)
  const [ filtros, setFiltros ] = useState([])
  const token = session.tokenSession
  const etiquetas = props.etiquetas
  const componentRef = useRef()
  const pageStyle = `
  @media print {
    .page-break {
      page-break-before: always;
    }
    h3 {
      font-size: 40px !important;
    }
    .qr svg {
      width: 50%;
      height: auto;
      display: block;
      margin: 0 auto;
    }
    .etiquetabodega {
      width: 100%
    }
  }  
`;

useEffect(() => {
  const items = procesarFiltros(etiquetas)
  setFiltros(items)
  obtenerPersonalizaciones()
}, []) // eslint-disable-line react-hooks/exhaustive-deps

const obtenerPersonalizaciones = async () => {
setLoadingEtiquetas(true)
return fetch(`${urlapi}/configuracion/personalizar`,{
  method:'POST',
  body: JSON.stringify({
    tipo: "personalizacion",
    sub_tipo: "etiqueta"
  }),
  headers: {
      'Content-Type':'application/json',
      'Authorization': `Bearer: ${token}`
  }
})
.then(res => {
  if(res.status === 401) return dispatch(cerrarSesion())
  return res.json()
})
.then(res => {
console.log(res)
if(!res){
  toast.error("Sin respuesta")
} else if(res.errorMessage){
  toast.error(res.errorMessage)
} else if(typeof res === "object"){
  console.log("personalizacion etiquetas,", res)
  setPersonalizacionEtiqueta(res)
}
return setLoadingEtiquetas(false)
})
.catch(error => {
toast.error(error.message)
return setLoadingEtiquetas(false)
})
}

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle
  })

  const procesarFiltros = (datos) => {
    let filtros = [
      { value: "level1", label: "Zona 1", options: [] },
      { value: "level2", label: "Zona 2", options: [] },
      { value: "level3", label: "Zona 3", options: [] }
    ]
    for( const item of datos ){
      filtros.map((filtro,i_filtro) => {
          if(item[filtro.value]){
            const valor = item[filtro.value].toString().toLowerCase()
            const i = filtros[i_filtro].options.findIndex(e => e === valor)
            if( i < 0 ) filtros[i_filtro].options.push(valor)
          }
      } )
    }
    return filtros
  }

  const mostrarSegunPersonalizacion = () => {
    if(!personalizacionEtiqueta.detalles ) return <MostrarEtiquetas filtros={filtros} etiquetas={etiquetas} ref={componentRef} />
    return <MostrarEtiquetasPersonalizadas filtros={filtros} etiquetas={etiquetas} ref={componentRef} personalizacionEtiqueta={personalizacionEtiqueta} /> 
    }

  const render = () => {
    if(loadingEtiquetas) return <Spinner animation="border" />
    return  <div className="mx-2">
      <Button className='shadow w-100 mb-3' variant="success" onClick={() => handlePrint()}><i className="fas fa-barcode mb-0"></i><b> IMPRIMIR {etiquetas.length === 1 ? etiquetas.length + " ETIQUETA" : etiquetas.length + " ETIQUETAS"}
    </b></Button>
        {mostrarSegunPersonalizacion()}        
    </div>
  }

  return (
    <div>
      {render()}
    </div>
  );
}

export default Etiquetas;