import { useEffect, useState } from "react"
import { Button, Col, Row, Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import { key_local_storage_user, urlapi } from "../../lib/backend/data"
import { validateEmail } from "../../lib/helpers/helpers"
import Switch from "react-switch";

const CrearUsuario = (props) => {
    const [usuario, setUsuario] = useState({})
    const [creando, setCreando] = useState(false)
    const [show_password, setShowPassword] = useState(false)
    const user = JSON.parse(localStorage.getItem(key_local_storage_user))

    useEffect(() => {
        if(props.rut_empresa){
            usuario.propietario = props.rut_empresa
            setUsuario(usuario)
        }
    }, [])

    const crearNuevo = async () => {
        const requeridos = [
            { value:'nombres', label: 'Nombres' },
            { value:'apellidos', label: 'Apellidos' },
            { value:'email', label: 'Email' },
            { value:'password', label: 'Contraseña' },
            { value:'role', label: 'Rol de usuario' },
            { value:'propietario', label: 'Rut empresa' },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!usuario[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        if(validateEmail(usuario.email) !== true) return toast.error('Email inválido')
        if(usuario.password.length < 5) return toast.error('La contraseña debe tener al menos 5 carácteres')
        if(usuario.password !== usuario.password_confirm) return toast.error('Las contraseñas no coinciden')
        const loading = 'creandoUsuario'
        setCreando(true)
        return fetch(`${urlapi}/usuarios`,{
            method:'POST',
            body: JSON.stringify( usuario ),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){ 
                toast.success('Guardado exitosamente')
                setTimeout(() => {
                    return window.location = '/usuarios'
                }, 500);
            }
            setCreando(false)
        })
        .catch(error => {
            setCreando(false)
            toast.error("Error al realizar esta operación, intente nuevamente")
        })
    }

    const handleChangeUsuario = (e) => {
        const { name, value } = e.target
        usuario[name] = value
        return setUsuario(usuario)
    }

    const handleChangeChecked = (checked) => {
        setShowPassword(checked)
    }

    const formularioNuevo = () => {
        return <div>
            <Row>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>Nombres</label>
                        <input name="nombres"  className='form-control' onChange={handleChangeUsuario} />
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>Apellidos</label>
                        <input name="apellidos"  className='form-control' onChange={handleChangeUsuario} />
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>Email</label>
                        <input name="email" className='form-control' onChange={handleChangeUsuario}/>
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>ID Fiscal</label>
                        <input name="rut"  className='form-control' onChange={handleChangeUsuario} />
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>Fecha nacimiento</label>
                        <input name="fecha_nacimiento"  type="date" className='form-control' onChange={handleChangeUsuario} />
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>Teléfono</label>
                        <input name="phone"  className='form-control' onChange={handleChangeUsuario} />
                    </Col>
                    <Col md={3} className="mb-3">
                    <label className='form-control-label d-block'>Contraseña</label>
                    <input className='form-control mb-4' type={ show_password === true ? 'text' : 'password' } name="password" onChange={handleChangeUsuario} />
                    </Col>
                    <Col md={3} className="mb-3">
                    <label className='form-control-label d-block'>Repite Contraseña</label>
                    <input className='form-control mb-4' type={ show_password === true ? 'text' : 'password' } name="password_confirm" onChange={handleChangeUsuario} />
                    </Col>
                    <Col md={2} className="mb-3">
                    <label className='form-control-label d-block'>Mostrar contraseña</label>
                    <Switch onChange={handleChangeChecked} checked={show_password} />
                    </Col>
                    <Col md={3} className="mb-3">
                        <label className='form-control-label'>Rol de usuario</label>
                        <select name="role"  className='form-control' onChange={handleChangeUsuario}>
                            <option value="">Selecciona</option>
                            <option value="user">Usuario</option>
                            <option value="admin">Administrador</option>
                        </select>
                    </Col>
                <Col md={12}>
                    {
                        creando === true ? <Spinner animation="border" /> : <Button size="sm" variant="outline-success" onClick={()=>crearNuevo()} >CREAR NUEVO USUARIO</Button>
                    }
                </Col>
            </Row>
        </div>
    }

    return <div>
        {formularioNuevo()}
    </div>
}

export default CrearUsuario