import React from 'react';
import { url_images } from '../../lib/global/data'
import { Link, Navigate, useParams } from 'react-router-dom';
// import '../landing/actividadLanding.css'
import { Card, Col, Row } from 'react-bootstrap';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { data_landing } from './data';

const ActividadLanding = () => {
    const route = useParams()
    const { slug } = route
    if(!slug) return <Navigate to="/" />

    const pos_data = data_landing.findIndex(d => d.slug === slug)
    if(pos_data < 0) return <Navigate to="/" />

    const info = data_landing[pos_data]

    return (
        <>
            {/* NAVIGATION */}
            <Card className='shadow-sm' style={{ 
                backgroundImage: `url(${url_images}/background.webp)`,
                backgroundPosition: 'center center',
                backgroundSize: 'cover',
                width: '100%', 
                padding: 0 }}>
            <nav className="navbar navbar-expand-lg navbar-dark navbar-custom d-flex justify-content-between" style={{ width: '100%' }}>
                    <div>
                        <Link to='/' className="navbar-brand" style={{ fontSize: 16 }}>
                        <BiLeftArrowAlt size={20} /> Volver a inicio</Link>
                    </div>
            </nav>
            {/* HEADER */}
            <div className="header-product masthead text-center text-white">
                <div className="masthead-content my-4">
                    <Row className='justify-content-center'>
                        <Col md={8} xs={12}>
                        <h1 className="masthead-heading mb-0" style={{ fontWeight: 900, fontSize: 50 }}>{info.title}</h1>
                        <h2 className="masthead-subheading mb-3" style={{ fontWeight: 400, fontSize: 20 }}>{info.text}</h2>
                        {
                            info.links ? <Row className='justify-content-center'>
                                {
                                    info.links.map((item,it) => {
                                        const styleIcon = { size: 30, color: "white" }
                                        const Icono = item.icon ? item.icon : false
                                        return <Col md={2} key={`item-${it}`} className='mb-3'>
                                                <Link to={`/${item.link}`} className='text-white'>
                                                {Icono ? <Icono {...styleIcon} /> : false}
                                                <h5 style={{ fontSize: 15 }} className='mb-0 mt-2'>{item.title}</h5>
                                                </Link>
                                        </Col>
                                    })
                                }
                            </Row> : false
                        }
                        </Col>
                    </Row>
                </div>
            </div>
            </Card>
            {/* SECTIONS */}
            {
                info.areas.map((area,ia) => {
                    const derecha = ia%2 ? true : false
                    const fontSizeTitle = 35
                    return <section id="scroll" key={`area-${ia}`}>
                    <div className="container px-0">
                    <div className="row gx-5 align-items-center">
                            <div className="col-lg-6 ">
                                <div className="p-5">
                                    <h2 className="display-4" style={{ fontWeight: 700, fontSize: fontSizeTitle }}>{area.title}</h2>
                                    <p>{area.text}</p>
                                </div>
                            </div>
                            <div className="col-lg-6" style={{ order: derecha ? -1 : 1 }}>
                                    <div className="p-5 text-center">
                                        { area.image ? <img  style={{ width: "65%", marginTop: -70, marginBottom: -70 }} className="img-fluid rounded" src={area.image} alt="Imagen1" /> : false }
                                    </div>
                            </div>
                    </div>
                    </div>
                </section>
                })
            }
            {/* FOOTER */}
            <footer className="py-5 bg-black">
                <div className="container px-0"><p className="m-0 text-center text-primary small">Copyright &copy; Last-Mile 2023</p></div>
            </footer>
        </>
    )
};

export default ActividadLanding;