import { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Card, OverlayTrigger, Tooltip, Navbar, Nav } from 'react-bootstrap'
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import BoxList from './box-list';
import CargandoDetalleFull from './cargando_detalle_full';
import DetailFull from './detalle-full';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import HelperModal from '../general/helperModal';
import { obtenerFechaHoraZonaHorariaLocal, obtenerRangos } from '../../lib/helpers/dates';
import { Link } from 'react-router-dom';
import { rutas } from '../../lib/routes/routes';
import { FaFileExcel, FaPlusCircle } from 'react-icons/fa';
import { MdPlaylistAddCircle } from 'react-icons/md';

const PagosListado = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const dispatch = useDispatch()
    const [ proveedores, setProveedores ] = useState([])
    const [ loadingProveedores, setLoadingProveedores ] = useState(true)
    const [ patente, setPatente ] = useState('')
    const [ numeroInterno, setNumeroInterno ] = useState('')
    const [ removiendo, setRemoviendo ] = useState(false)
    const [ openModal, setOpenModal ] = useState(false)
    const [ vehiculoSeleccionado, setVehiculoSeleccionado ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const [ idSeleccionado, setIdSeleccionado ] = useState(false)
    const [ total, setTotal ] = useState(0)
    const [ pagina, setPagina ] = useState(1)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const trash = props.trash ? props.trash : false
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ crearNuevo, setCrearNuevo ] = useState(true)
    const [ parametroBusqueda, setParametroBusqueda ] = useState('')
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ incluyeEstadoExcel, setIncluyeEstadoExcel ] = useState(false)
    const [ metodosPago, setMetodosPago] = useState([])
    const propietario = props.propietario ? props.propietario : ""

    const obtenerProveedores = async (page, query)=>{
        setLoadingProveedores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        condicion.propietario = propietario
        return fetch(`${data.urlapi}/pagos/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingProveedores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingProveedores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setProveedores(res.datos)
                setMetodosPago(res.metodos_pago)
                setTotal(res.total)
            }
            return setLoadingProveedores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingProveedores(false)
        })
    }

    const onClickProveedor = async (id) => {
        setLoadingDetalle(true)
        setIdSeleccionado(id)
        setCrearNuevo(false)
        return fetch(`${data.urlapi}/pagos?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingDetalle(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingDetalle(false)
            } else if(res._id){
                setVehiculoSeleccionado(res)
            }
            return setLoadingDetalle(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingDetalle(false)
        })
    }

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerProveedores(page)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const modalFiltro = () => {
        const estados = []
        const sub_estados = []
        return <Modal show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Filtrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xs={12} className="mb-2">
                    <label className='d-block form-control-label'>Fecha</label>
                    <select name="tipo_fecha" className='form-control'>
                        <option value="createdAt">Creación</option>
                        <option value="updatedAt">Actualización</option>
                        <option value="compromiso">Compromiso</option>
                        <option value="ruta">Fecha ruta</option>
                    </select>
                </Col>
                <Col md={12}>
                    <Button size="sm" variant="success">FILTRAR</Button>
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        return <div>
            <Pagination size='sm'>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
        </div>
    }

    useEffect(() => {
        obtenerProveedores(1, false)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const tabla_registros = (registros) => {
        if(loadingProveedores===true) return cargandoScreen()
        if(registros.length < 1) return  <div>
            <h5>Nada por aquí</h5>
            <p>No hay registros en esta sección</p>
        </div>
        
        return <div>
            <h5>{(registros.length * pagina) - registros.length + 1} - {registros.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b></h5>
            {paginacion(data.pagina, total)}
            {
                        registros.map(orden => {
                            let seleccionado = false
                            if(idSeleccionado === orden._id) seleccionado = true
                            return <div key={orden._id} className="mb-3">
                                <BoxList proveedor={orden} seleccionado={seleccionado} onClickProveedor={(id) => onClickProveedor(id)} />
                            </div>
                        })
                    }
        </div>
    }

    const handleSubmitPalabra = (e) => {
        e.preventDefault()
        if(!parametroBusqueda){
            const condicion = {}
            setCondicionBusqueda(condicion)
            setPagina(1)
            return obtenerProveedores(1, condicion)
        }
        const condicion = { $text: { $search: parametroBusqueda }}
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerProveedores(1, condicion)
    }
    const reiniciar = () => {
        setPagina(1)
        setCondicionBusqueda({})
        obtenerProveedores(1, {})
    }

    const onProveedorGuardado = (data) => {
        console.log(data)
        const i = proveedores.findIndex(a => a._id === data._id)
        if(i < 0) return false
        proveedores[i] = data
        return setProveedores(prev => [...[], ...proveedores])
    }

    const crearProveedor = () => {
        setLoadingDetalle(true)
            setIdSeleccionado(false)
            setVehiculoSeleccionado(false)
            setTimeout(() => {
                setLoadingDetalle(false)
            }, 100);
    }

    const handleChangePalabra = (e) => {
        const { value } = e.target
        return setParametroBusqueda(value)
    }

    const onProveedorNuevo = (data) => {
        return setProveedores(prev => {
            let actual = [...prev]
            actual.unshift(data)
            crearProveedor()
            return [...[], ...actual]
        })
    }

    const onFieldDeleted = (id) => {
        const i = proveedores.findIndex(c => c._id === id)
        if(i < 0) return false
        proveedores.splice(i,1)
        setVehiculoSeleccionado(false)
        return setProveedores(prev => [...[], ...proveedores])
    }

    const descargarReporteExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reporte/productos`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda,
                incluir_estados: incluyeEstadoExcel
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }
 
    const show_menu_top = <div>
    <Navbar bg="light" variant="light" className='mb-3'>
          {/* <Navbar.Brand >Órdenes</Navbar.Brand> */}
          <Nav className="mr-auto">
            <Nav.Link ><p className='mb-0' onClick={()=>crearProveedor()}><FaPlusCircle /> CREAR NUEVO</p> </Nav.Link>
            {/* <Nav.Link ><Link to={`/${rutas.productos_importar_excel.slug}`}><FaFileExcel /> IMPORTAR EXCEL</Link> </Nav.Link> */}
          </Nav>
          {/* <Form inline>vehiculos_importar_excel
            <FormControl type="text" placeholder="Search" className="mr-sm-2" />
            <Button variant="outline-info">Search</Button>
          </Form> */}
        </Navbar>
</div>



    return <div>
        <h4 style={{ fontSize: 23 }}>Pagos</h4>
        {show_menu_top}
        
        {modalFiltro()}
            {/* <h4 style={{ fontSize: 23 }}>Productos <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip>Crear nuevo</Tooltip>
                            }
                            >
                            <i className="fa-solid text-primary hover mr-2 fa-circle-plus" onClick={()=>crearProveedor()} ></i>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip>Importar excel</Tooltip>
                            }
                            >
                            <Link to={`/${rutas.productos_importar_excel.slug}`}><MdPlaylistAddCircle size={27} style={{ marginTop:-7 }} /> </Link>
                          </OverlayTrigger>

                          {/* <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip>Descargar Reporte Excel</Tooltip>
                            }
                            >
                            <i className="fa-solid text-primary hover fa-circle-plus" onClick={()=>descargarReporteExcel()} ></i>
                          </OverlayTrigger> 
                             
              </h4> */}
        <Row>
            <Col md={3}>
            {/* <Button size="sm" variant='light' disabled={loadingExcel} className='w-100 mb-2 mt-2' onClick={() => descargarReporteExcel()} ><i className="fa-solid fa-file-excel"></i>  DESCARGAR REPORTE</Button> */}
                <Row>
                    <Col md={12}>
                        <form onSubmit={handleSubmitPalabra}>
                        <input className='mb-3 form-control' placeholder='BUSCAR' onChange={handleChangePalabra} />
                        </form>
                    </Col>
                </Row>
                {tabla_registros(proveedores)}
            </Col>
            <Col md={9}>
                <Card className='px-4 py-3'>
                    {
                        loadingDetalle === true ? <CargandoDetalleFull /> : <DetailFull 
                        onFieldDeleted={(id) => onFieldDeleted(id)}
                        crear={crearNuevo} 
                        onProveedorNuevo={(data) => onProveedorNuevo(data)} 
                        token={token} 
                        proveedor={vehiculoSeleccionado}
                        onProveedorGuardado={(data) => onProveedorGuardado(data)} 
                        propietario={propietario}
                        metodosPago={metodosPago}
                        />
                    }
                </Card>
            </Col>
        </Row>
    </div>

}

export default PagosListado