import { Card, Col, Row } from "react-bootstrap"
import { fechaATexto, fechaATextoSimpleCorto } from "../../lib/helpers/helpers"
import { FiMapPin } from "react-icons/fi"

const BoxList = (props) => {
    const proveedor = props.proveedor ? props.proveedor : false
    const seleccionado = props.seleccionado ? props.seleccionado : false

    if(!proveedor) return false

    const onClickProveedor = (id) => {
        if(props.onClickProveedor) return props.onClickProveedor(id)
        return false
    }

    return <Card bg={ seleccionado === true ? 'primary' : 'white' } className={`p-3 ${seleccionado === true ? 'text-white' : 'text-dark'}`} onClick={() => onClickProveedor(proveedor._id)}>
    <Row>
        <Col><p className="mb-1 text-white" style={{ padding: "1px 17px", fontSize: 10,  width: "fit-content", backgroundColor: "#848484", borderLeft:"3px solid #d96720" }}>{proveedor.codigo_item}</p></Col>
        <Col className="text-right"><h6 style={{ fontSize:10, fontWeight:'lighter' }} className="mb-2 text-right">Creado <b>{ fechaATextoSimpleCorto(proveedor.createdAt)}</b></h6></Col>
    </Row>
    <h6 style={{ fontSize: 17 }} className="mb-1">{proveedor.description}</h6>
</Card>
}

export default BoxList