const esquema_default = {
    actividad: { active: true, alias: "Viajes" },
    entregas: { active: true, alias: "Torre de control Envios" },
    ordenes: { active: true, alias: "Reportes y Pedidos Envios" },
    ordenes_2: { active: true, alias: "Rutas asignadas" },
    auditoria_app_movil: { active: true, alias: "Auditoria por pedido" },
    auditoria_vehiculos: { active: true, alias: "Auditoria por vehículo" },
    rendimiento_proveedores: { active: true, alias: "OTIF proveedores" },
    notificaciones: { active: true, alias: "Crear notificaciones" },
    tickets: { active: true, alias: "Tickets" },
    tickets_todos: { active: true, alias: "Todos" },
    tickets_pendientes: { active: true, alias: "Tickets pendientes" },
    tickets_proveedor: { active: true, alias: "Tickets recibidos" },
    tarifas: { active: true, alias: "Tarifas" },
    flota: { active: true, alias: "Flota" },
    mi_flota: { active: true, alias: "Mi flota" },
    monitoreo_flota: { active: true, alias: "Monitoreo flota" },
    informes_rastreo_conductor: { active: true, alias: "Informes de rastreo" },
    automatizaciones: { active: true, alias: "Alertas y notificaciones" },
    auditoria_conductores: { active: true, alias: "Reporte App Conductor" },
    expedicioness: { active: true, alias: "Expediciones" },
    invitaciones: { active: true, alias: "Invitaciones de rutas" },
    ruteo_simple: { active: true, alias: "Planificador" },
    ruteo_simple_2: { active: true, alias: "Planificar visitas" },
    ruteo_avanzado: { active: true, alias: "Planificar entregas" },
    facturacion: { active: true, alias: "Facturación" },
    configuracion: { active: true, alias: "Configuración" },
    general: { active: true, alias: "General" },
    empresa: { active: true, alias: "Mi Empresa" },
    productos: { active: true, alias: "Productos" },
    clientes: { active: true, alias: "Clientes" },
    mis_clientes: { active: true, alias: "Mis Clientes" },
    tarifas_clientes: { active: true, alias: "Tarifas" },
    clientes_bodegas: { active: true, alias: "Bodegas" },
    contactos: { active: true, alias: "Contactos" },
    integracioness: { active: true, alias: "Integraciones" }
}  

function reducer( state = esquema_default, { type, payload } ){
    switch( type ){
        case 'cambiarEsquemaModular': {
            return payload
        }
        case 'reiniciarInterfaz':
            return esquema_default
        default:
            return state
    }
}

export default reducer