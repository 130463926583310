import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BarChart } from '@mui/x-charts';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import { urlapi } from '../../lib/backend/data';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { cerrarSesion } from '../../redux/actions/session';
import { Button, Col, Row, Spinner } from 'react-bootstrap';
const theme = createTheme({ })

const procesarGraficosHistorico = (datos, key) => {
    let datos_procesados = [[ 'Mes', 'Operaciones' ]]
    datos.map(m => {
        return datos_procesados.push([ m.month, m[key] ])
    })
    return datos_procesados
}

const obtenerDatosTotales = (datos, keys) => {
    const datos_totales = [['Mes', ...keys]];
    datos.forEach(m => {
        const fila = [m.month];
        keys.forEach(key => {
            fila.push(m[key]);
        });
        datos_totales.push(fila);
    });
    return datos_totales;
}


const GraficoRecursos = (props) => {
    const {
        propietario
    } = props
    const dispatch = useDispatch()
    const [ loading, setLoading ] = useState(true)
    const [ registro, setRegistros ] = useState([])
    const [ llaveInfo, setLlaveInfo ] = useState('registro')
    const ahora = DateTime.now();
    const anoActual= ahora.toFormat('yyyy');
    const [ano, setAno] = useState(anoActual)
    const session = useSelector(state => state.miusuario)

    useEffect(() => {
        obtenerConteos()
    }, [])

    const obtenerConteos = async ()=>{
        setLoading(true)
        return fetch(`${urlapi}/conteo/recursos/graph`,{
            method:'POST',
            body: JSON.stringify({
                ano,
                propietario
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray((res))){
                setRegistros(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const datos_totales = obtenerDatosTotales(registro, ['api_google_maps', 'api_mapbox', 'enviar_emails', 'enviar_whatsapp'])

    const data_total = {
        labels: datos_totales.map((d,p) => {
            if(!p) return false
            return d[0]
        }).filter(e => e),
        datasets: [
            {
                label: 'API Google Maps',
                maxBarThickness: 20,
                data: datos_totales.map((d,p) => {
                    if(!p) return undefined
                    return d[1] ? d[1] : 0
                }).filter(e => typeof e !== "undefined"),
                backgroundColor: 'rgb(27, 54, 101)',
                borderWidth: 0,
            },
            {
                label: 'API Mapbox',
                maxBarThickness: 20,
                data: datos_totales.map((d,p) => {
                    if(!p) return undefined
                    return d[2] ? d[2] : 0
                }).filter(e => typeof e !== "undefined"),
                backgroundColor: 'rgb(54, 162, 235)',
                borderWidth: 0,
            },
            {
                label: 'API Twilio (Mailing)',
                maxBarThickness: 20,
                data: datos_totales.map((d,p) => {
                    if(!p) return undefined
                    return d[3] ? d[3] : 0
                }).filter(e => typeof e !== "undefined"),
                backgroundColor: 'rgb(255, 99, 132)',
                borderWidth: 0,
            },
            {
                label: 'API Twilio (Mailing)',
                maxBarThickness: 20,
                data: datos_totales.map((d,p) => {
                    if(!p) return undefined
                    return d[4] ? d[4] : 0
                }).filter(e => typeof e !== "undefined"),
                backgroundColor: 'rgb(75, 192, 192)',
                borderWidth: 0,
            },
        ],
    }


    const datos_procesados = procesarGraficosHistorico(registro, llaveInfo)
    // console.log(datos_procesados, 'dataprocesados')
    console.log(registro, 'registro')
    // console.log(llaveInfo, 'llaveInfo')

    const data = {
        labels: datos_procesados.map((d,p) => {
            if(!p) return false
            return d[0]
        }).filter(e => e),
        datasets: [
          {
            label: 'Cantidad de operaciones',
            maxBarThickness: 20,
            data: datos_procesados.map((d,p) => {
                if(!p) return undefined
                return d[1] ? d[1] : 0
            }).filter(e => typeof e !== "undefined"),
            backgroundColor: 'rgb(27, 54, 101)',
            //borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 0,
          },
        ],
      }

      const handleChange = (e) => {
        const { value } = e.target
        return setLlaveInfo(value)
      }

    return <div>
        <h4 className='mb-3'><b>Consumo de API</b></h4>
        <Row>
            {/* <Col md={3}>
                <label className="form-control-label d-block">Selecciona el tipo de servicio</label>
                <select className="form-control mb-3" value={llaveInfo} onChange={handleChange}>
                    <option value="api_google_maps">Consumo de API Google Maps</option>
                    <option value="api_mapbox">Consumo de API MapBox</option>
                    <option value="enviar_emails">Consumo de API Twilio (Mailing)</option>
                    <option value="enviar_whatsapp">Consumo de API Twilio (Whatsapp)</option>
                </select>
            
            </Col> */}
            <Col md={10}>
            <div>
            <ThemeProvider theme={theme}>
            <BarChart
                {...{ width: 1000, height: 300, xAxis: [{ data: data.labels, scaleType: 'band' }],
                }}
                series={[ 
                    { data: data_total.datasets[0].data, label: 'API Google Maps' },
                    { data: data_total.datasets[1].data, label: 'API Mapbox' },
                    { data: data_total.datasets[2].data, label: 'API Twilio (Mailing)' },
                    { data: data_total.datasets[3].data, label: 'API Twilio (Whatsapp)' },
                ]}         
                />
            </ThemeProvider> 
            </div>
            </Col>
            <Col md={2} className='pl-0'>
            <label className="form-control-label d-block">Año</label>
            <input className='form-control' type="number" value={ano} onChange={(e) => setAno(e.target.value)} />

            <label className="form-control-label d-block">Click para actualizar</label>
            <Button className='w-100' size='small' disabled={loading} onClick={() => obtenerConteos()} >{ loading ? <div><Spinner animation='border' size="sm" /> CARGANDO...</div> : "ACTUALIZAR"}</Button>

            </Col>
        </Row>

    </div>

}

export default GraficoRecursos