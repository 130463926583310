import { useState, useEffect } from "react"
import { Button, Col, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from "react-bootstrap"
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/session";
import CamposObligatoriosLeyenda from "../general/campos_obligatorios";
import MediosBox from "../general/mediosbox";
// import EtiquetasRelaciones from "../etiquetas-relaciones/etiquetas_relaciones";

const DetailFull = (props) => {
    const [proveedor, setProveedor] = useState(props.proveedor ? props.proveedor : false)
    const [loading, setLoading] = useState(false)
    const [removiendo, setRemoviendo] = useState(false)
    const [nuevoProveedor, setNuevoProveedor] = useState({})
    const session = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const token = session.tokenSession
    const [crear, setCrear] = useState(props.crear ? props.crear : false)
    const propietario = props.propietario ? props.propietario : ""
    const metodos_pago = props.metodosPago ? props.metodosPago : []

    const dispatch = useDispatch()

    const handleChange = (e) => {
        const { name, value } = e.target
        proveedor[name] = value
        return setProveedor(proveedor)
    }

    const crearNuevo = async () => {
        const requeridos = [
            { value:'monto', label: 'Monto'},
            { value: "titulo", label: "Título" },
            { value: "id_target", label: "Metodo de Pago" },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!nuevoProveedor[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: "${faltantes.join(', ')}". No deben haber letras en campos numericos.`)
        let payload = {...nuevoProveedor, propietario}
        setLoading(true)
        return fetch(`${data.urlapi}/pagos`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                    if(props.onProveedorNuevo) props.onProveedorNuevo(pros)
                    toast.success(`Realizado exitosamente`)
                    setNuevoProveedor({...{}, ...{}})
              }
              
              return setLoading(false)
          })
          .catch(error => {
            toast.error("No se pudo crear el producto es probable que un codigo item ya exista, revisalo")
                return setLoading(false)
          })
    }

    const guardarCambios = async () => {
        setLoading(true)
        return fetch(`${data.urlapi}/pagos`, {
            method: 'PUT',
            body: JSON.stringify(proveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Hubo un error de servidor', this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage, this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                  if(props.onProveedorGuardado) props.onProveedorGuardado(proveedor)
                  toast.success(`Guardado exitosamente`)
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro, No deben haber letras en campos numericos, ni campos vacios.')
                return setLoading(false)
          })
    }

    const handleChangeNuevo = (e) => {
        const { name, value } = e.target
        return setNuevoProveedor(prev => {
            let actual = { ...prev }
            actual[name] = value
            return actual
        })
    }

    const formularioNuevo = () => {
        return <div>
            <Row>
                <Col md={12}>
                    <h4>Crear nuevo Pago</h4>
                    <p>Los pagos forman parte de tu empresa, están relacionados directamente a la rentabilización de la misma en esta plataforma.</p>
                    <CamposObligatoriosLeyenda />
                </Col>
                <Col md={6} className="mb-3">
                    <label className="form-control-label d-block">Concepto *</label>
                    <input className="form-control" name="titulo" defaultValue={nuevoProveedor.titulo} onChange={handleChangeNuevo} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Monto *</label>
                    <input className="form-control" name="monto" type="number" defaultValue={nuevoProveedor.monto} onChange={handleChangeNuevo} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Forma de Pago *</label>
                    <select className="form-control" name="forma_pago" value={nuevoProveedor.forma_pago} onChange={handleChangeNuevo}>
                    <option value="">Seleccione</option>
                    <option value="transferencia">Transferencia</option>
                    <option value="mercado-pago">Mercado pago</option>
                    <option value="transbank">Transbank</option>
                  </select>
                </Col>
                {
                    nuevoProveedor.forma_pago === "transferencia" ? <Col md={12} className="mb-3">
                        <label className="form-control-label d-block">Cuenta Destino *</label>
                        <select className="form-control" name="id_target" value={nuevoProveedor.id_target} onChange={handleChangeNuevo}>
                        <option value="">Seleccione</option>
                        {
                        metodos_pago?.map(metodo => <option key={`Metodo-pago-${metodo.titulo}`} value={metodo._id}>{`${metodo.titulo}, ${metodo.tipo}`}</option>)
                        }
                    </select>
                    </Col> : false
                }
                <Col md={12} className="mb-3">
                    <label className="form-control-label d-block">Tipo de pago *</label>
                    <select className="form-control mb-3" name="tipo" value={nuevoProveedor.tipo} onChange={handleChangeNuevo}>
                    <option value="">Seleccione</option>
                    <option value="suscripcion">Suscripción</option>
                    <option value="extra">Adicional</option>
                  </select>

                  {
                    nuevoProveedor.tipo === "suscripcion" ? <Row>
                        <Col md={4} className="mb-3">
                        <label className="form-control-label d-block">Mes *</label>
                        <select className='form-control' name="mes" value={nuevoProveedor.mes} onChange={handleChangeNuevo} >
                            <option value="">Seleccione</option>
                            <option value="01">Enero</option>
                            <option value="02">Febrero</option>
                            <option value="03">Marzo</option>
                            <option value="04">Abril</option>
                            <option value="05">Mayo</option>
                            <option value="06">Junio</option>
                            <option value="07">Julio</option>
                            <option value="08">Agosto</option>
                            <option value="09">Septiembre</option>
                            <option value="10">Octubre</option>
                            <option value="11">Noviembre</option>
                            <option value="12">Diciembre</option>
                        </select>
                        </Col>
                        <Col md={3}>
                            <label className="form-control-label d-block">Año *</label>
                            <input type="number" name="ano" value={nuevoProveedor.ano} className="form-control" placeholder="año" onChange={handleChangeNuevo} />
                        </Col>
                    </Row> : false
                }

                </Col>
                <Col md={12}>
                    {
                        loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>crearNuevo()} >CREAR NUEVO</Button>
                    }
                </Col>
            </Row>
        </div>
    }

    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/pagos?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                if(props.onFieldDeleted) props.onFieldDeleted(res._id)
            }
            setProveedor(false)
            setCrear(true)
            toast.success(`Eliminado exitosamente`)
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const View = () => {
        if(!proveedor) return formularioNuevo()
        return <div>
            <h4 className="mb-0">Detalles del pago <b>{proveedor.titulo}</b></h4>
            {
                removiendo === true ? <Spinner animation="border" /> : <Button variant="link" className="text-danger p-0 d-block mb-3" onClick={()=>solicitarEliminar(proveedor._id)} >Eliminar</Button>
            }
            <Tabs defaultActiveKey="editar" >
            <Tab eventKey="editar" className="mt-4" title="Ficha informativa ">
                <Row>
        <Col md={6} className="mb-3">
                    <label className="form-control-label d-block">Concepto *</label>
                    <input className="form-control" name="titulo" defaultValue={proveedor.titulo} onChange={handleChange} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Monto *</label>
                    <input className="form-control" name="monto" type="number" defaultValue={proveedor.monto} onChange={handleChange} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Forma de Pago *</label>
                    <select className="form-control" name="forma_pago" value={proveedor.forma_pago} onChange={handleChange}>
                    <option value="">Seleccione</option>
                    <option value="transferencia">Transferencia</option>
                    <option value="mercado-pago">Mercado pago</option>
                    <option value="transbank">Transbank</option>
                  </select>
                </Col>
                {
                    proveedor.forma_pago === "transferencia" ? <Col md={12} className="mb-3">
                        <label className="form-control-label d-block">Cuenta Destino *</label>
                        <select className="form-control" name="id_target" value={proveedor.id_target} onChange={handleChange}>
                        <option value="">Seleccione</option>
                        {
                        metodos_pago?.map(metodo => <option key={`Metodo-pago-${metodo.titulo}`} value={metodo._id}>{`${metodo.titulo}, ${metodo.tipo}`}</option>)
                        }
                    </select>
                    </Col> : false
                }
                <Col md={12} className="mb-3">
                    <label className="form-control-label d-block">Tipo de pago *</label>
                    <select className="form-control mb-3" name="tipo" value={proveedor.tipo} onChange={handleChange}>
                    <option value="">Seleccione</option>
                    <option value="suscripcion">Suscripción</option>
                    <option value="extra">Adicional</option>
                  </select>

                  {
                    proveedor.tipo === "suscripcion" ? <Row>
                        <Col md={4} className="mb-3">
                        <label className="form-control-label d-block">Mes *</label>
                        <select className='form-control' name="mes" value={proveedor.mes} onChange={handleChange} >
                            <option value="">Seleccione</option>
                            <option value="01">Enero</option>
                            <option value="02">Febrero</option>
                            <option value="03">Marzo</option>
                            <option value="04">Abril</option>
                            <option value="05">Mayo</option>
                            <option value="06">Junio</option>
                            <option value="07">Julio</option>
                            <option value="08">Agosto</option>
                            <option value="09">Septiembre</option>
                            <option value="10">Octubre</option>
                            <option value="11">Noviembre</option>
                            <option value="12">Diciembre</option>
                        </select>
                        </Col>
                        <Col md={3}>
                            <label className="form-control-label d-block">Año *</label>
                            <input type="number" name="ano" value={proveedor.ano} className="form-control" placeholder="año" onChange={handleChange} />
                        </Col>
                    </Row> : false
                }

                </Col>
                <Col md={12}>
            {
                loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>guardarCambios()} >GUARDAR CAMBIOS</Button>
            }
        </Col>
                <Col md={12}>
                {/* <hr />
                <EtiquetasRelaciones id_target={proveedor._id} tipo_destino="pago" tipo_origen="etiquetas"/> */}
                <hr />
                <h4 className='mb-3 ml-2'><b>Documentos asociados</b></h4>
                <MediosBox typeView="modal" id_destino={proveedor._id} />                        
                </Col>
        </Row>
            </Tab>
           {/* <Tab eventKey="bodegas" className="mt-4" title="Bodegas" >
                <DestinatariosBodegasListado id_cliente={proveedor._id} condicion_default={{ id_cliente: proveedor._id }} />
            </Tab>
            <Tab eventKey="contactos" className="mt-4" title="Contactos" >
                <DestinatariosContactosListado id_cliente={proveedor._id} condicion_default={{ id_cliente: proveedor._id }} />
            </Tab> */}
        </Tabs>
        </div>
    }

    return <div>
        {View()}
    </div>
}

export default DetailFull