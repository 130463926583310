import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { ReactSortable } from "react-sortablejs";

const BasicFunction = (props) => {
  const [state, setState] = useState([
    { id: 1, name: "shrek" },
    { id: 2, name: "fiona" },
  ]);

  return <div>
    <div>{JSON.stringify(state)}</div>
    <ReactSortable list={state} setList={(d) => console.log(d)}>
      {state.map((item) => (
        <Card className="p-2 mb-3" key={item.id}>{item.name}</Card>
      ))}
    </ReactSortable>
  </div>
};

export default BasicFunction;
